import React, { useState, useEffect } from 'react';
import { Divider, FormHelperText } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from './Tooltip';
import Button from '@mui/material/Button';
import InputMask from 'react-input-mask';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import './DadosEmpresas.css';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import axios from 'axios';
import { API_BASE, API_URL } from './API/apiManager';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { set } from 'react-hook-form';

function DadosEmpresas({ getCNPJ }) {
  const [cnpj, setCNPJ] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState('');
  const [status, setStatus] = useState(false);
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUF] = useState('');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [statusMensagens, setStatusMensagens] = useState(false);

  useEffect(() => {
    window.sessionStorage.setItem('cnpj', cnpj);
    window.sessionStorage.setItem('razaoSocial', razaoSocial);
    window.sessionStorage.setItem('inscricaoEstadual', inscricaoEstadual);
    window.sessionStorage.setItem('inscricaoMunicipal', inscricaoMunicipal);
    window.sessionStorage.setItem('endereco', endereco);
    window.sessionStorage.setItem('numero', numero);
    window.sessionStorage.setItem('complemento', complemento);
    window.sessionStorage.setItem('bairro', bairro);
    window.sessionStorage.setItem('cidade', cidade);
    window.sessionStorage.setItem('uf', uf);
    window.sessionStorage.setItem('status', status);
    window.sessionStorage.setItem('statusMensagens', statusMensagens);
  }, [
    cnpj,
    razaoSocial,
    inscricaoEstadual,
    inscricaoMunicipal,
    endereco,
    numero,
    complemento,
    bairro,
    cidade,
    uf,
    status,
    statusMensagens,
  ]);

  let savedCNPJ = window.sessionStorage.getItem('cnpj');
  let savedRazaoSocial = window.sessionStorage.getItem('razaoSocial');
  let savedInscricaoEstadual =
    window.sessionStorage.getItem('inscricaoEstadual');
  let savedInscricaoMunicipal =
    window.sessionStorage.getItem('inscricaoMunicipal');
  let savedEndereco = window.sessionStorage.getItem('endereco');
  let savedNumero = window.sessionStorage.getItem('numero');
  let savedComplemento = window.sessionStorage.getItem('complemento');
  let savedBairro = window.sessionStorage.getItem('bairro');
  let savedCidade = window.sessionStorage.getItem('cidade');
  let savedUF = window.sessionStorage.getItem('uf');
  let savedStatus = window.sessionStorage.getItem('status');
  let savedStatusMensagens = window.sessionStorage.getItem('statusMensagens');

  const handleCloseSuccess = () => {
    setOpen(false);
    setOpenSuccess(false);
  };

  const handleCloseError = () => {
    openFailure(false);
    setOpenFailure(false);
  };

  const checkCEP = (event) => {
    event.preventDefault();
    const cep = event.target.value.replace(/\D/g, '');
    if (!event.target.value) return;
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setZipCode(data);
        setEndereco(
          data.logradouro.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        );
        setBairro(data.bairro.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        setCidade(
          data.localidade.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        );
        setUF(data.uf);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  function RemoverCaracteres(value) {
    return value.normalize('NFD').replace(/[^a-zA-Z0-9]/g, '');
  }
  function RemoverAcentos(value) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Base: API_BASE,
        CNPJCompany: cnpj,
        RazaoSocial: RemoverAcentos(razaoSocial),
        InscricaoEstadual: RemoverCaracteres(inscricaoEstadual),
        InscricaoMunicipal: RemoverCaracteres(inscricaoMunicipal),
        Endereco: RemoverCaracteres(endereco),
        NumEndereco: RemoverCaracteres(numero),
        ComplEndereco: RemoverCaracteres(complemento),
        Cidade: RemoverCaracteres(cidade),
        Bairro: RemoverCaracteres(bairro),
        UF: RemoverCaracteres(uf),
        Ativo: status,
        User: sessionStorage.getItem('userSession'),
        ManageMessages: statusMensagens,
      };
      // console.log(headers);
      const res = await axios.post(
        API_URL + '/companie',
        {},
        {
          headers,
        },
      );

      if (res.status === 200) {
        getCNPJ(cnpj);
        setCNPJ(savedCNPJ);
        setRazaoSocial(savedRazaoSocial);
        setInscricaoEstadual(savedInscricaoEstadual);
        setInscricaoMunicipal(savedInscricaoMunicipal);
        setEndereco(savedEndereco);
        setNumero(savedNumero);
        setComplemento(savedComplemento);
        setCidade(savedCidade);
        setUF(savedUF);
        setStatus(savedStatus);
        setStatusMensagens(savedStatusMensagens);
        setOpenSuccess(true);
      } else if (res.status !== 200) {
        setOpenFailure(true);
      }
    } catch (err) {
      setOpenFailure(true);
    }
  };

  const toggleHandler = (event) => {
    setStatus(event.target.checked);
  };

  const handlerSwitch = (event) => {
    setStatusMensagens(event.target.checked);
  };

  //console.log(statusMensagens);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const textoTooltip = (
    <p>
      Atenção: Ao ativar essa opção as mensagens serão automaticamente
      <br />
      marcadas como lidas em seus devidos órgãos e toda gestão será feita pelo
      Sonar!
    </p>
  );

  function validaCNPJ(cnpj) {
    var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    var c = String(cnpj).replace(/[^\d]/g, '');

    if (c.length !== 14) return false;

    if (/0{14}/.test(c)) return false;

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if (c[12] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

    for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if (c[13] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

    return true;
  }

  return (
    <div>
      <Divider textAlign="center">Dados Cadastrais</Divider>
      <div className="inputsForm">
        <div id="formEmpresas">
          <form onSubmit={handleSubmit} method="post">
            <div className="FormCNPJ">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <InputMask
                  mask="99.999.999/9999-99"
                  className="CNPJForm"
                  value={cnpj}
                  required
                  placeholder="CNPJ"
                  id="padraoInput"
                  onChange={(e) => setCNPJ(e.target.value.replace(/\D/g, ''))}
                />

                <FormHelperText
                  textAlign="center"
                  style={{ position: 'relative', bottom: '14px' }}
                >
                  {' '}
                  <p
                    style={{
                      fontWeight: 'bold',
                      color: 'red',
                    }}
                  >
                    {cnpj === ''
                      ? ' '
                      : validaCNPJ(cnpj) === true
                      ? 'CNPJ válido!'
                      : 'CNPJ INVÁLIDO. Digite novamente!'}
                  </p>
                </FormHelperText>
              </div>
              <input
                className="RazaoSocialForm"
                style={{ marginBottom: '23px' }}
                type="text"
                value={razaoSocial || savedRazaoSocial}
                placeholder="Razão Social"
                id="padraoInput"
                onChange={(e) => setRazaoSocial(e.target.value.toLowerCase())}
              />
            </div>
            <div className="divInscricoes">
              <input
                className="InscEstForm"
                type="text"
                value={inscricaoEstadual || savedInscricaoEstadual}
                placeholder="Inscrição Estadual"
                onChange={(e) =>
                  setInscricaoEstadual(e.target.value.toLowerCase())
                }
              />
              <input
                className="InscMunForm"
                type="text"
                value={inscricaoMunicipal || savedInscricaoMunicipal}
                placeholder="Inscrição Municipal"
                onChange={(e) =>
                  setInscricaoMunicipal(e.target.value.toLowerCase())
                }
              />
              <input
                type="text"
                placeholder="Simples Nacional"
                className="simplesNacional"
              />
            </div>
            <div className="SwitchEmpresa">
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ 'aria-label': 'controlled' }}
                      value={statusMensagens || savedStatusMensagens}
                      checked={statusMensagens}
                      onChange={handlerSwitch}
                    />
                  }
                />
                <span className="textTooltip">
                  Deixar o Sonar gerenciar suas mensagens?
                </span>

                <Tooltip content={textoTooltip}>
                  <span className="iconTooltip">
                    <HelpIcon sx={{ color: '#2b2c5f' }} />
                  </span>
                </Tooltip>
              </div>
              <div>
                <Stack direction="row" spacing={2} alignItems="center">
                  Situação Empresa?
                  <FormControlLabel
                    control={
                      <Switch
                        value={status || savedStatus}
                        checked={status}
                        onChange={toggleHandler}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{ m: 1 }}
                      />
                    }
                  />
                </Stack>
              </div>
            </div>
            <Divider>Endereço</Divider>
            <div className="divEnderecoCompleto">
              <div className="divCep">
                <InputMask
                  className="CEPForm"
                  size="9"
                  onBlur={checkCEP}
                  mask="99999-999"
                  type="text"
                  name="cep"
                  placeholder="CEP"
                />
              </div>

              <div className="divEndereco">
                <input
                  className="EnderecoForm"
                  onBlur={checkCEP}
                  value={endereco || savedEndereco}
                  type="text"
                  name="rua"
                  placeholder="Endereço"
                  onChange={(e) => setEndereco(e.target.value.toLowerCase())}
                />

                <input
                  className="NumForm"
                  value={numero || savedNumero}
                  type="text"
                  name="numero"
                  placeholder="Nº"
                  id="inputEndereco"
                  onChange={(e) => setNumero(e.target.value.toLowerCase())}
                />
              </div>
              <div className="enderecoComplementos">
                <div className="divComplemento">
                  <input
                    className="CompleForm"
                    value={complemento || savedComplemento}
                    type="text"
                    name="complemento"
                    placeholder="Complemento"
                    id="inputEndereco"
                    onChange={(e) =>
                      setComplemento(e.target.value.toLowerCase())
                    }
                  />
                  <input
                    className="BairroForm"
                    value={bairro || savedComplemento}
                    type="text"
                    name="bairro"
                    placeholder="Bairro"
                    id="inputEndereco"
                    onChange={(e) => setBairro(e.target.value.toLowerCase())}
                  />
                </div>
                <div className="divCidade">
                  <input
                    className="CidadeForm"
                    value={cidade || savedCidade}
                    type="text"
                    name="cidade"
                    placeholder="Cidade"
                    onChange={(e) => setCidade(e.target.value.toLowerCase())}
                  />
                  <input
                    className="UFForm"
                    type="text"
                    name="uf"
                    value={uf || savedUF}
                    placeholder="UF"
                    onChange={(e) => setUF(e.target.value.toLowerCase())}
                  />
                </div>
              </div>
            </div>
            <div className="buttonSalvar">
              <Button variant="contained" id="Button" type="submit">
                Salvar <SaveAltIcon />
              </Button>
              <div
                className="message"
                style={{
                  position: 'relative',
                  top: '20px',
                  fontSize: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Snackbar
                  open={openSuccess}
                  autoHideDuration={3000}
                  onClose={handleCloseSuccess}
                >
                  <Alert
                    severity="success"
                    onClose={handleCloseSuccess}
                    autoHideDuration={3000}
                  >
                    Empresa criada com sucessso!
                  </Alert>
                </Snackbar>
                <Snackbar
                  open={openFailure}
                  autoHideDuration={3000}
                  // OnClose={handleCloseError}
                >
                  <Alert
                    severity="error"
                    // onClose={handleCloseError}
                    autoHideDuration={3000}
                  >
                    Erro ao criar Empresa!
                  </Alert>
                </Snackbar>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DadosEmpresas;
