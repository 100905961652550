import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from './Account';
import NavBar from '../Components/navBar';


const Status = ({ children }) => {
  const [status, setStatus] = useState(false);
  const { getSession, logout } = useContext(AccountContext);

  const nav = useNavigate();

  useEffect(() => {
    const Session = async () => {
      getSession().then((session) => {
        setStatus(true);
      });
    };
    Session();
  }, [getSession()]);


  return (
    <React.Fragment>
      {status ? (
        <section>
          <NavBar logout={logout} /> 
          {children}
        </section>
      ) : (
        nav('/login')
      )}
    </React.Fragment>
  );
};

export default Status;
