import React, { useState, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  styled,
  Box,
  Modal,
  CircularProgress,
  IconButton,
} from '@mui/material';
import './styles.css';
import axios from 'axios';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Gauge from '../Gauge/Gauge';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { clear } from '@testing-library/user-event/dist/clear';
import { useEffect } from 'react';
import { API_BASE, API_URL } from '../../API/apiManager';
import { Divider } from '@mui/material';

const MyButton = styled(Button)({
  backgroundColor: '#2b2c5f',
  color: '#fff',
  marginBottom: '10px',
  '&:hover': {
    backgroundColor: '#40416f',
  },
});

const MyButtonCancel = styled(Button)({
  backgroundColor: '#e42517',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#e63a2e',
  },
});

const MyButtonConfirm = styled(Button)({
  backgroundColor: 'green',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#4B8333',
  },
});

const DialogAvançada = ({ CNPJConsulta }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [cnd, setCND] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [atividadePrincipal, setAtividadePrincipal] = useState([]);
  const [atividadesSecundarias, setAtividadesSecundarias] = useState([]);
  const [qsa, setQsa] = useState([]);
  const [protestos, setProtestos] = useState([]);
  const [error, setError] = useState('');
  const [score, setScore] = useState([]);
  const [spc, setSPC] = useState([]);
  const [openFailure, setOpenFailure] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
    setOpen(false);
    getData();
  };

  const handleCloseError = () => {
    setOpenFailure(false);
    setOpenModal(false);
  };

  const handleCloseModal = () => setOpenModal(false);

  const getData = () => {
    setLoading(true);
    if (loading) {
      setTimeout(() => {
        return (
          <div>
            <p style={{ color: '#2b2c5f' }}>
              A consulta está levando mais tempo que o esperado. Por favor
              verifique o histórico de consultas.
            </p>
          </div>
        );
      }, 5000);
    }
    axios
      .get(API_URL + '/queries/cnpj', {
        headers: {
          Base: API_BASE,
          CNPJ: CNPJConsulta,
          QueryType: '2',
          user: sessionStorage.getItem('userSession'),
        },
      })
      .then((response) => {
        console.log(data);
        // if (response.data !== 200) {
        //   setError(
        //     'O serviço de origem parece estar indisponível. Por favor, tente novamente mais tarde.',
        //   );
        //   setOpenFailure(true);
        // } else {
        setData(response.data);
        setCND(response.data.cnd);
        setAddresses(response.data.addresses);
        setQsa(response.data.qsa);
        setAtividadePrincipal(response.data.atividade_principal);
        setAtividadesSecundarias(response.data.atividades_secundarias);
        setProtestos(response.data.protestos);
        setScore(response.data.score);
        setSPC(response.data.spc);
        // }
        setLoading(false);
      });
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  function numberToReal(numero) {
    var numero = numero.toFixed(2).split('.');

    numero[0] = 'R$ ' + numero[0].split(/(?=(?:...)*$)/).join('.');

    return numero.join(',');
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 850,
    maxHeight: 700,
    minHeight: loading ? 0 : 80,
    minWidth: loading ? '150px' : 330,
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflowY: 'scroll',
    overflowX: 'hidden',
    p: 4,
  };

  function CND() {
    if (cnd.nome) {
      return (
        <div className="divCND">
          <p>
            Razão Social: <strong>{cnd.nome}</strong>
          </p>
          <p>
            CNPJ: <strong>{cnd.cnpj}</strong>
          </p>
          <p>
            Certidão Emitida em:{' '}
            <strong>{cnd.normalizado_consulta_datahora}</strong>{' '}
          </p>
          <p>
            Válida até: <strong>{cnd.validade_data}</strong>
          </p>
          <p>
            Código de controle da certidão:{' '}
            <strong>{cnd.certidao_codigo}</strong>
          </p>
          <p>
            Baixe aqui a Certidão em PDF:{' '}
            <IconButton>
              <a href={cnd.site_receipt} target="_blank" rel="noreferrer">
                <CloudDownloadIcon sx={{ color: '#2b2c5f' }} />
              </a>
            </IconButton>
          </p>
        </div>
      );
    } else if (cnd.pefin) {
      return (
        <div className="divCND">
          Pendência Financeira
          <p>
            Quantidade total de pendências financeiras:{' '}
            <strong>{cnd.pefin.summary.count}</strong>
          </p>
          <p>
            Valor total de pendências financeiras:{' '}
            <strong>{cnd.pefin.summary.balance}</strong>
          </p>
          <Divider style={{ marginTop: '3px', marginBottom: '3px' }} />
          Restrições Financeiras
          <p>
            Quantidade total de restrições financeiras:{' '}
            <strong>{cnd.refin.summary.count}</strong>
          </p>
          <p>
            Valor total de restrições financeiras:{' '}
            <strong>{cnd.refin.summary.balance}</strong>
          </p>
        </div>
      );
    } else {
      return (
        <p>
          Podem haver pendências financeiras ou processuais no CNPJ da empresa
          consultada. Não será possível gerar a certidão.
        </p>
      );
    }
  }
  function SPC() {
    if (spc.spcAnnotations.inclusionDate) {
      return (
        <div>
          <p>
            Data da inclusão:{' '}
            <strong>{spc.spcAnnotations.inclusionDate}</strong>
          </p>
          <p>
            Data da ocorrência:{' '}
            <strong>{spc.spcAnnotations.ocurrenceDate}</strong>
          </p>
          <p>
            Credor da anotação:{' '}
            <strong>{spc.spcAnnotations.creditorName}</strong>
          </p>
          <p>
            Origem da anotação: <strong>{spc.spcAnnotations.origin}</strong>
          </p>
          <p>
            Data da negativação mais recente:{' '}
            <strong>{spc.spcAnnotations.summary.firstOcurrence}</strong>
          </p>
          <p>
            Data da negativação mais antiga:{' '}
            <strong>{spc.spcAnnotations.summary.lastOcurrence}</strong>
          </p>
          <p>
            Total de anotações:{' '}
            <strong>{spc.spcAnnotations.summary.count}</strong>
          </p>
          <p>
            Valor total das anotações:{' '}
            <strong>{spc.spcAnnotations.summary.balance}</strong>
          </p>
          <p>
            Quantidade de consultas no mês atual:{' '}
            <strong>
              {spc.spcMarketInquirySummary.inquiryQuantity.actual}
            </strong>
          </p>
          <p>
            Quantidade de consultas nos últimos 6 meses:{' '}
            <strong>
              {
                spc.spcMarketInquirySummary.inquiryQuantity
                  .creditInquiriesQuantity
              }
            </strong>
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            Quantidade de consultas no mês atual:{' '}
            <strong>
              {spc.spcMarketInquirySummary.inquiryQuantity.actual}
            </strong>
          </p>
          <p>
            Quantidade de consultas nos últimos 6 meses:{' '}
            <strong>
              {
                spc.spcMarketInquirySummary.inquiryQuantity
                  .creditInquiriesQuantity[0].occurrences
              }
            </strong>
          </p>
        </div>
      );
    }
  }

  function Protestos() {
    if (protestos.cartorios) {
      return (
        <div>
          <p>
            CPF / CNPJ:{' '}
            {protestos.cartorios.RJ[0].protestos[0].cpf_cnpj
              .replace(/\D/g, '')
              .replace(
                /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
                '$1.$2.$3/$4-$5',
              )}
          </p>
          <p>
            Data Protesto:{' '}
            {protestos.cartorios.RJ[0].protestos[0].data_protesto_string}
          </p>
          <p>
            Data Protesto:{' '}
            {protestos.cartorios.RJ[0].protestos[0].data_protesto_string}
          </p>
          <p>Data e Hora da Consulta: {protestos.consulta_datahora}</p>
          <p>R$ {protestos.cartorios.RJ[0].protestos[0].valor_string}</p>
          <p>
            Verificar Protestos:
            <IconButton>
              <a href={protestos.site_receipt} target="_blank" rel="noreferrer">
                <CloudDownloadIcon sx={{ color: '#2b2c5f' }} />
              </a>
            </IconButton>
          </p>
          <p>
            Tem anuência?{' '}
            <strong>
              {protestos.cartorios.RJ[0].protestos[0].tem_anuencia}
            </strong>
          </p>
          <p>Quantidade de Protestos: {protestos.quantidade_titulos}</p>
        </div>
      );
    } else if (protestos.bankrupts.bankruptsResponse) {
      return (
        <div>
          Falências
          <p>
            Data da falência:{' '}
            <strong>{protestos.bankrupts.bankruptsResponse.eventDate}</strong>
          </p>
          <p>
            Tipo de falência:{' '}
            <strong>{protestos.bankrupts.bankruptsResponse.eventType}</strong>
          </p>
          <p>
            Origem:{' '}
            <strong>{protestos.bankrupts.bankruptsResponse.origin}</strong>
          </p>
          <p>
            Cidade de origem da falência:{' '}
            <strong>{protestos.bankrupts.bankruptsResponse.city}</strong>
          </p>
          <p>
            Estado de origem da falência:{' '}
            <strong>{protestos.bankrupts.bankruptsResponse.state}</strong>
          </p>
          <p>
            Data da negativação mais recente:{' '}
            <strong>
              {protestos.bankrupts.bankruptsResponse.summary.firstOcurrence}
            </strong>
          </p>
          <p>
            Data da negativação mais antiga:{' '}
            <strong>
              {protestos.bankrupts.bankruptsResponse.summary.lastOcurrence}
            </strong>
          </p>
          <p>
            Quantidade total de falências:{' '}
            <strong>
              {protestos.bankrupts.bankruptsResponse.summary.count}
            </strong>
          </p>
          <p>
            Valor total das falências:{' '}
            <strong>
              {protestos.bankrupts.bankruptsResponse.summary.balance}
            </strong>
          </p>
          <Divider style={{ marginTop: '3px', marginBottom: '3px' }} />
          Ações Judiciais
          <p>
            Data da ação judicial:{' '}
            <strong>
              {
                protestos.judgementFilings.judgementFilingsResponse
                  .occurrenceDate
              }
            </strong>
          </p>
          <p>
            Natureza da dívida:{' '}
            <strong>
              {protestos.judgementFilings.judgementFilingsResponse.legalNature}
            </strong>
          </p>
          <p>
            Valor da dívida:{' '}
            <strong>
              {protestos.judgementFilings.judgementFilingsResponse.amount}
            </strong>
          </p>
          <p>
            ID da Vara Judicial:{' '}
            <strong>
              {protestos.judgementFilings.judgementFilingsResponse.civilCourt}
            </strong>
          </p>
          <p>
            Cidade:{' '}
            <strong>
              {protestos.judgementFilings.judgementFilingsResponse.city}
            </strong>
          </p>
          <p>
            Estado:{' '}
            <strong>
              {protestos.judgementFilings.judgementFilingsResponse.state}
            </strong>
          </p>
          <p>
            Data da negativação mais recente:{' '}
            <strong>{protestos.judgementFilings.summary.firstOcurrence}</strong>
          </p>
          <p>
            Data da negativação mais antiga:{' '}
            <strong>{protestos.judgementFilings.summary.lastOcurrence}</strong>
          </p>
          <p>
            Quantidade total de ações judiciais:{' '}
            <strong>{protestos.judgementFilings.summary.count}</strong>
          </p>
          <p>
            Valor total das ações judiciais:{' '}
            <strong>{protestos.judgementFilings.summary.balance}</strong>
          </p>
          <p>
            Quantidade de consultas nos cinco últimos meses:{' '}
            {protestos.judgementFilings.inquiryCompanyResponse.results.map(
              (item) => (
                <div>
                  <p>
                    Data da consulta:<strong>{item.occurrences}</strong>
                  </p>
                  <p>
                    CNPJ da empresa consultante:
                    <strong>{item.companyName}</strong>
                  </p>
                  <p>
                    Razão Social da empresa consultante:
                    <strong>{item.companyDocumentId}</strong>
                  </p>
                  <p>
                    Quantidade de consultas no dia:
                    <strong>{item.daysQuantity}</strong>
                  </p>
                </div>
              ),
            )}
          </p>
          <p>
            Quantidade de consultas do mês atual:{' '}
            <strong>
              {
                protestos.judgementFilings.inquiryCompanyResponse.quantity
                  .actual
              }
            </strong>
          </p>
        </div>
      );
    } else {
      return (
        <div>
          Falências
          <p>
            Quantidade total de falências:{' '}
            <strong>{protestos.bankrupts.summary.count}</strong>
          </p>
          <p>
            Valor total das falências:{' '}
            <strong>{protestos.bankrupts.summary.balance}</strong>
          </p>
          <Divider style={{ marginTop: '3px', marginBottom: '3px' }} />
          Ações Judiciais
          <p>
            Quantidade total de ações judiciais:{' '}
            <strong>{protestos.judgementFilings.summary.count}</strong>
          </p>
          <p>
            Valor total das ações judiciais:{' '}
            <strong>{protestos.judgementFilings.summary.balance}</strong>
          </p>
        </div>
      );
    }
  }
  function numberToReal(numero) {
    var numero = numero.toFixed(2).split('.');

    numero[0] = 'R$ ' + numero[0].split(/(?=(?:...)*$)/).join('.');

    return numero.join(',');
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: data.cnpj,
  });

  return (
    <div>
      <MyButton variant="contained" onClick={handleOpen}>
        Nova Consulta
      </MyButton>

      <Dialog open={open} onClose={handleClose}>
        <div className="dialogContainer">
          <h3 style={{ color: '#2b2c5f' }}>Consulta de CNPJ</h3>
          <DialogContent>
            <DialogContentText>
              Tem certeza que deseja consultar o CNPJ selecionado?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MyButtonCancel onClick={handleClose}>Cancelar</MyButtonCancel>
            <MyButtonConfirm onClick={handleOpenModal} autoFocus>
              Confirmo
            </MyButtonConfirm>
          </DialogActions>
        </div>
      </Dialog>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={style}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <CircularProgress style={{ color: '#2b2c5f' }} />
              {/* <div
                id="showMe"
                style={{
                  color: '#2b2c5f',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                <p>A consulta está levando mais tempo que o esperado.</p>
                <p> Por favor, verifique o histórico de consultas.</p>
              </div> */}
            </Box>
          ) : (
            <div className="divDadosEmpresa" ref={componentRef}>
              {/* {error !== '' ? (
                <Snackbar
                  open={openFailure}
                  onClose={handleCloseError}
                  autoHideDuration={6000}
                >
                  <Alert
                    autoHideDuration={6000}
                    sx={{ width: '100%' }}
                    severity="error"
                    onClose={handleCloseError}
                  >
                    {error}
                  </Alert>
                </Snackbar>
              ) : ( */}
              <div>
                <IconButton onClick={handlePrint}>
                  <PrintIcon style={{ color: '#2b2c5f' }} />
                </IconButton>
                <p className="pEmpresa">Dados Empresa </p>
                <Gauge score={score} />
                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">Número de Inscrição e Razão Social</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    <tr className="tr">
                      <td className="td">
                        {data.cnpj} - {data.nome}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">Última Atualização</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    <tr className="tr">
                      <td className="td">{data.ultima_atualizacao}</td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">Data de Abertura</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    <tr className="tr">
                      <td className="td">{data.abertura}</td>
                    </tr>
                  </tbody>
                </table>

                {atividadePrincipal.map((atividade) => (
                  <table className="table">
                    <thead className="thead">
                      <tr className="tr">
                        <th className="th">Atividade Econômica Primária</th>
                      </tr>
                      <tr className="tr">
                        <th className="th">Código</th>
                        <th className="th">Descrição</th>
                      </tr>
                    </thead>
                    <tbody className="tbody">
                      <tr className="tr">
                        <td className="td">{atividade.code}</td>
                        <td className="td">{atividade.text}</td>
                      </tr>
                    </tbody>
                  </table>
                ))}

                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">Atividade Econômica Secundárias</th>
                    </tr>
                    <tr className="tr">
                      <th className="th">Código</th>
                      <th className="th">Descrição</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    {atividadesSecundarias.map((atividade) => (
                      <tr className="tr">
                        <td className="td">{atividade.code}</td>
                        <td className="td">{atividade.text}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">
                        Código e Descrição da Natureza Jurídica
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    <tr className="tr">
                      <td className="td">{data.natureza_juridica}</td>
                    </tr>
                  </tbody>
                </table>

                <div>
                  <table className="table">
                    <thead className="thead">
                      <tr className="tr">
                        <th className="th">Logradouro</th>
                        <th className="th">Número</th>
                        <th className="th">Complemento</th>
                      </tr>
                    </thead>
                    <tbody className="tbody">
                      <tr className="tr">
                        <td className="td">{data.logradouro}</td>
                        <td className="td">{data.numero}</td>
                        <td className="td">{data.complemento}</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="table">
                    <thead className="thead">
                      <tr className="tr">
                        <th className="th">CEP</th>
                        <th className="th">Bairro</th>
                        <th className="th">Município</th>
                        <th className="th">UF</th>
                      </tr>
                    </thead>
                    <tbody className="tbody">
                      <tr className="tr">
                        <td className="td">{data.cep}</td>
                        <td className="td">{data.bairro}</td>
                        <td className="td">{data.municipio}</td>
                        <td className="td">{data.uf}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <p className="pDemaisEnd">Demais Endereços</p>
              <table className="table">
                <thead className="thead">
                  <tr className="tr">
                    <th className="th">Logradouro</th>
                    <th className="th">Número</th>
                    <th className="th">Complemento</th>
                  </tr>
                </thead>
                <tbody className="tbody">
                  {addresses.map((address) => (
                    <tr className="tr">
                      <td className="td">{address.addressMain}</td>
                      <td className="td">{address.number}</td>
                      <td className="td">{address.complement}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <table className="table">
                <thead className="thead">
                  <tr className="tr">
                    <th className="th">CEP</th>
                    <th className="th">Bairro</th>
                    <th className="th">Município</th>
                    <th className="th">UF</th>
                  </tr>
                </thead>
                <tbody className="tbody">
                  {addresses.map((address) => (
                    <tr className="tr">
                      <td className="td">{address.zipCode}</td>
                      <td className="td">{address.neighborhood}</td>
                      <td className="td">{address.city}</td>
                      <td className="td">{address.state}</td>
                    </tr>
                  ))}
                </tbody>
              </table> */}

                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">Endereço Eletrônico</th>
                      <th className="th">Telefone</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    <tr className="tr">
                      <td className="td">{data.email}</td>
                      <td className="td">{data.telefone}</td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">Capital Social</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    <tr className="tr">
                      <td className="td">
                        R$ {numberToReal(+data.capital_social)}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">Quadro Societário</th>
                    </tr>
                    <tr className="tr">
                      <td className="th">Nome</td>
                      <td className="th">Qualificação</td>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    {qsa.map((qsa) => {
                      if (qsa.qual_rep_legal && qsa.nome_rep_legal) {
                        return (
                          <tr className="tr">
                            <td className="td">
                              Representante: {qsa.nome_rep_legal}
                            </td>
                            <td className="td">{qsa.qual_rep_legal}</td>
                          </tr>
                        );
                      }
                      return (
                        <tr className="tr">
                          <td className="td">{qsa.nome}</td>
                          <td className="td">{qsa.qual}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">Situação Cadastral</th>
                      <th className="th">Data da Situação Cadastral</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    <tr className="tr">
                      <td className="td">{data.situacao}</td>
                      <td className="td">{data.abertura}</td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">
                        Certidão Negativa de Débitos aos Tributos Federais e à
                        Dívida Ativa da União
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    <tr className="tr">
                      <td className="td">
                        <CND />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">SPC</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    <tr className="tr">
                      <td className="td">
                        <div className="divCND">
                          <SPC />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <thead className="thead">
                    <tr className="tr">
                      <th className="th">Protestos</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    <tr className="tr">
                      <td className="td">{<Protestos />}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* )} */}
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default DialogAvançada;
