import React from 'react'
import Tabs from './Components/CaixaMensagens'
import Head from './Components/Head'
import Header from './Components/Header'
import NavBar from './Components/navBar'

function MsgFederal() {
  return (
    <div>
      <Head title="Sonar | E-CAC"/>
      <Header/>
      <Tabs/>
    </div>
  )
}

export default MsgFederal