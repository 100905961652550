import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import './IconsAcoes.css';
import warning from '../Components/imgs/warning.svg';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import EditNotificacoes from './EditNotificacoes';
import { API_URL, API_BASE } from './API/apiManager';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 310,
  height: 250,
  color: '#252525',
  bgcolor: 'background.paper',
  border: '1px solid #2b2c5f',
  boxShadow: 24,
  borderRadius: '0.75rem',
  p: 4,
};

const stylesModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 900,
  minWidth: 310,
  height: 'auto',
  color: '#252525',
  bgcolor: 'background.paper',
  border: '1px solid #2b2c5f',
  boxShadow: 24,
  p: 4,
};

export default function AcoesNotificacoes({ notificacao }) {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseShowModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleDeleteNotificacao = async (event) => {
    event.preventDefault();
    try {
      var myHeaders = new Headers();
      myHeaders.append('idnotification', notificacao.IDNOTIFICATION);
      myHeaders.append('Base', API_BASE);

      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow',
      };
      fetch(API_URL + '/notifications', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.statusCode === 200) {
            setMessage('Notificação deletada com sucesso!');
            window.location.reload(true);
          } else {
            setMessage(result.body.Mensagem);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <IconButton onClick={handleShowModal}>
        <EditIcon />
      </IconButton>
      <Modal open={showModal} onClose={handleCloseShowModal}>
        <Box sx={stylesModal}>
          <EditNotificacoes styles={{ padding: 0 }} notificacao={notificacao} />
        </Box>
      </Modal>
      <IconButton onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Modal open={open}>
        <Box sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '1rem',
            }}
          >
            <img src={warning} />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h1"
            textAlign="center"
          >
            Cuidado!
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, mb: 2 }}
            textAlign="center"
          >
            Deseja excluir esta <b style={{ color: 'red' }}>NOTIFICAÇÃO</b>?
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={handleDeleteNotificacao}
              variant="contained"
              endIcon={<DeleteIcon />}
              sx={{ mr: 2 }}
              style={{ backgroundColor: 'green' }}
            >
              Sim, excluir agora!
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              style={{ backgroundColor: 'red' }}
            >
              Cancelar!
            </Button>
          </div>
          <div
            className="message"
            style={{
              position: 'relative',
              top: '10px',
              fontSize: '1rem',
            }}
          >
            {message ? (
              <p style={{ color: 'green' }}>{message}</p>
            ) : (
              <p style={{ color: 'red' }}>{message}</p>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
