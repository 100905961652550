import React, { useState } from 'react';
import Highcharts from 'highcharts/highstock';
import PieChart from 'highcharts-react-official';
import { useMediaQuery } from '@mui/material';

const PizzaChart = ({ consultasAvancadas, consultasBasicas }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const options = {
    chart: {
      type: 'pie',
      width: isMobile ? 330 : 800,
    },
    title: {
      text: 'Consultas por Tipo',
      style: {
        color: '#2b2c5f',
        fontFamily: 'Calibri',
      },
    },
    pie: {
      colors: ['#2b2c5f', '#9b2c5e'],
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: '',
        color: '#006600',
        lineWidth: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 3,
          states: {
            hover: {
              enabled: true,
              lineWidth: 1,
            },
          },
        },
        data: [
          {
            name: 'Consulta Básica',
            y: consultasBasicas,
            sliced: true,
            color: '#2b2c5f',
          },
          {
            name: 'Consulta Avançada',
            y: consultasAvancadas,
            sliced: true,
            color: '#9b2c5e',
          },
        ],
      },
    ],
  };

  return (
    <div>
      {' '}
      <PieChart highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PizzaChart;
