import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import axios from 'axios';
import { API_BASE, API_URL } from './API/apiManager';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const temasButtons = createTheme({
  pallete: {
    primary: {
      main: '#2b2c5f',
    },
  },
});
const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? '#fff' : '#2b2c5f',
    backgroundColor: state.isSelected ? '#2b2c5f' : '#fff',
  }),
  control: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: '#fff',
    borderColor: '#2b2c5f',
    boxShadow: 'none',
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: '#252525' }),
};

function SelectMunicipal({ status }) {
  const [selectStatus, setSelectedStatus] = useState([]);
  const [message, setMessage] = useState('');
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const [openError, setOpenError] = React.useState(false);
  const [openEmpty, setOpenEmpty] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
    setOpenEmpty(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenWarning(false);
  };

  useEffect(() => {
    const getIDFUP = () => {
      const arr = [];
      let result = status;
      result.map((status) => {
        return arr.push(
          {
            value: 1,
            label: 'Processando',
          },
          {
            value: 2,
            label: 'Finalizado',
          },
        );
      });
      setSelectedStatus(arr);
    };
    getIDFUP();
  }, []);

  const options = [
    {
      value: 1,
      label: 'Processando',
    },
    {
      value: 2,
      label: 'Finalizado',
    },
  ];

  const handleChange = (selectedOption) => {
    setSelected(selectedOption.value);
  };

  const handlePostSelectStatus = async (e) => {
    e.preventDefault();
    const header = status.map(async (status) => {
      const headers = {
        Base: API_BASE,
        user: sessionStorage.getItem('userSession'),
        idmail: status.IDMAIL,
        idfup: selected,
      };
      // console.log(headers);
      const res = await axios.post(
        API_URL + '/mailbox/mailfup',
        {},
        {
          headers: headers,
        },
      );

      const response = res.data;
      if (response.statusCode === 200) {
        setOpenSuccess(true);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          window.location.reload(true);
        }, 2000);
      } else {
        setOpenError(true);
      }
    });
  };

  return (
    <>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: '100%' }}
        >
          Sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Erro no envio!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openEmpty}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Certificado, Senha e ao menos uma filial não pode estar em branco!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openWarning}
        autoHideDuration={4000}
        onClose={handleCloseWarning}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseWarning}
          severity="warning"
          sx={{ width: '100%' }}
        >
          Dados faltante!
        </Alert>
      </Snackbar>
      <form onSubmit={handlePostSelectStatus}>
        <ThemeProvider theme={temasButtons}>
          <div className="containerSelect">
            <Select
              options={options}
              onChange={handleChange}
              autoWidth
              isSearchable
              styles={customStyles}
              placeholder="Selecione um status"
              getOptionLabel={(options) => options['label']}
            />
          </div>
          <div
            className="containerAcoes"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Stack direction="row" spacing={2}>
              <Button
                type="submit"
                variant="outlined"
                startIcon={<CheckIcon />}
                sx={{
                  color: '#fff',
                  backgroundColor: '#2b2c5f',
                  '&:hover': {
                    backgroundColor: '#3b4e82 ',
                    color: '#fff',
                    borderColor: '#2b2c5f',
                  },
                }}
              >
                Confirmar Ação
              </Button>
            </Stack>
          </div>
          {message ? (
            <p
              style={{
                color: 'green',
                textAlign: 'center',
                marginTop: '1rem',
              }}
            >
              {message}
            </p>
          ) : (
            <p
              style={{
                color: 'red',
                textAlign: 'center',
                marginTop: '1rem',
              }}
            >
              {message}
            </p>
          )}
        </ThemeProvider>
      </form>
    </>
  );
}

export default SelectMunicipal;
