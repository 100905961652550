import Head from './Components/Head';
import Header from './Components/Header';
import NavBar from './Components/navBar';
import React, { useState, useEffect } from 'react';
import { Divider, InputLabel, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material';
import { FormControl } from '@mui/material';
import { Select } from '@mui/material';
import { OutlinedInput } from '@mui/material';
import { Table } from '@mui/material';
import { TableBody } from '@mui/material';
import './Components/Orgaos.css';
import { Paper } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableContainer } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import './Certificados.css';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import { API_URL, API_BASE } from './Components/API/apiManager';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Certificados() {
  const theme = useTheme();

  const [openError, setOpenError] = React.useState(false);
  const [openEmpty, setOpenEmpty] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [empresaGet, setEmpresaGet] = useState([]);
  const [empresaName, setEmpresaName] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [file, setFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [cnpjCheck, setCNPJCheck] = useState('');
  const [loading, setLoading] = useState(false);
  const [preSignedURL, setPreSignedURL] = useState('');
  const [certUploadName, setCertUploadName] = useState('');
  const [certPass, setCertPass] = useState('');
  const [cryptoCertPass, setCryptoCertPass] = useState('');
  const [selectedSubs, setSelectedSubs] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const user = sessionStorage.getItem('userSession');
  const [refresh, setRefresh] = useState(false);

  const tema = createTheme({
    palette: {
      neutral: {
        main: '#2b2c5f',
        contrastText: '#fff',
      },
      typography: {
        fontFamily: 'Calibri',
      },
    },
  });

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
    setOpenEmpty(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenWarning(false);
  };

  useEffect(() => {
    axios
      .get(API_URL + '/companies', {
        headers: {
          Base: API_BASE,
        },
      })
      .then((response) => {
        setEmpresaGet(response.data);
      });
  }, []);

  async function handleGet(cnpj) {
    let requestOptions = {
      method: 'GET',
      headers: { CNPJCompany: `${cnpj}`, Base: API_BASE },
      redirect: 'follow',
    };
    const response = await fetch(API_URL + '/certificates', requestOptions);
    const data = await response.json();
    const result = await data;
    setCertificate(result);
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setEmpresaName(typeof value === 'string' ? value.split(',') : value);
    const cnpj = value.substring(0, 14);
    setCNPJCheck(cnpj);
    handleGet(cnpj);
  };

  const compareDates = function (certExpirationDate) {
    console.log(certExpirationDate, 'certExpirationDate');
    const dateComponents =  certExpirationDate =! null ? certExpirationDate.split('/') : null;
    const day = parseInt(dateComponents[0], 10);
    const month = parseInt(dateComponents[1], 10);
    const year = parseInt(dateComponents[2], 10);
  
    const certDate = new Date(year, month - 1, day);
  
    const monthLater = new Date();
    const today = new Date();
    monthLater.setMonth(today.getMonth() - 1);
  
    console.log(today, 'today');
    console.log(certDate, 'certDate');
    console.log(monthLater, 'monthLater');
  
    if (certDate < today) {
      return <CloseIcon fontSize="small" style={{ color: '#FF0032' }} />;
    } else if (today.getMonth() + 1 === certDate.getMonth()) {
      return <WarningIcon fontSize="small" style={{ color: '#FEDB39' }} />;
    } else {
      return <CheckIcon fontSize="small" style={{ color: 'green' }} />;
    }
  };
  

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, certificate.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCert = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setIsFilePicked(true);

      getPreSignedURL();
    }
  };

  const getPreSignedURL = async () => {
    const uploadName = uuidv4() + '.pfx';
    setCertUploadName(uploadName);

    const headers = {
      Base: API_BASE,
      Filename: uploadName,
    };

    const url = API_URL;

    await axios
      .get(url + '/certificates/presignedurl', { headers })
      .then((response) => {
        setPreSignedURL(response.data);
      })
      .catch((error) => error);
  };

  const handleCryptPass = () => {
    if (file) {
      const encryptKey = 'gSCEgjSmwgrDGdwgkJJeboFdyMUWoEHgLVY2Gaa7';
      const encrypted = CryptoJS.AES.encrypt(certPass, encryptKey).toString();

      setCryptoCertPass(encrypted);
    }
  };

  const handleSubmitCert = async () => {
    if (selectedSubs.length > 0 && file && certPass) {
      setLoadingSave(true);

      const fileUpload = file;

      const options = {
        method: 'PUT',
        data: fileUpload,
        url: preSignedURL,
        headers: { 'Content-Type': 'application/x-pkcs12', Base: API_BASE },
      };

      try {
        const response = await axios(options);

        if (response.status === 200) {
          handlePostCert(selectedSubs);
        }
      } catch (error) {
        setLoadingSave(false);
        console.error('error', error);
      }
    } else {
      setOpenEmpty(true);
      setLoadingSave(false);

      // Aqui vem a chamada do warning do snackbar
    }
  };

  /* const handlePostCert = async (subs) => {
    console.log(subs);
    certificate.map(async (certificado) => {
      if (
        certificado.EXPIRATIONDATE != null &&
        certificado.SERIALNUMBER != ''
      ) {
        const headers = {
          certName: certUploadName,
          certPass: cryptoCertPass,
          user: user,
        };
        try {
          const res = await axios.put(
            API_URL + '/certificates',
            {},
            { headers: headers },
          );
          console.log(res);
          if (res.statusCode === 200) {
            setOpenSuccess(true);
            setTimeout(() => {
              setLoading(false);
              window.location.reload(true);
            }, 2000);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        const headers = {
          cnpjcompany: cnpjCheck,
          certpass: cryptoCertPass,
          certname: certUploadName,
          user: sessionStorage.getItem('userSession'),
        };

        const options = {
          method: 'POST',
          url: API_URL + '/certificates',
          headers: headers,
        };

        for (const sub of subs) {
          console.log(sub);

          headers['cnpjsubsidiary'] = sub;

          try {
            const response = await axios(options);
            console.log(response.data);
            setLoadingSave(false);
            setOpenSuccess(true);
          } catch (error) {
            setOpenError(true);
            setLoadingSave(false);
            console.error('error', error);
          }
        }

        setLoadingSave(false);
      }
    });
  }; */

  const handlePostCert = async (subs) => {
    const headers = {
      Base: API_BASE,
      cnpjcompany: cnpjCheck,
      certpass: cryptoCertPass,
      certname: certUploadName,
      user: sessionStorage.getItem('userSession'),
    };

    const options = {
      method: 'POST',
      url: API_URL + '/certificates',
      headers: headers,
    };

    for (const sub of subs) {
      headers['cnpjsubsidiary'] = sub;

      try {
        const response = await axios(options);

        setLoadingSave(false);
        setOpenSuccess(true);
        window.location.reload(true);
      } catch (error) {
        setOpenError(true);
        setLoadingSave(false);
        console.error('error', error);
      }
    }

    setLoadingSave(false);
  };

  const handleDeleteCert = async () => {
    if (selectedSubs.length) {
      setLoadingDelete(true);

      const headers = {
        Base: API_BASE,
        Accept: 'application/json',
      };

      const options = {
        method: 'DELETE',
        url: API_URL + '/certificates',
        headers: headers,
      };

      for (const sub of selectedSubs) {
        headers['cnpjsubsidiary'] = sub;

        try {
          const response = await axios(options);
          setLoadingDelete(false);
          setOpenSuccess(true);
          window.location.reload(true);
        } catch (error) {
          setOpenError(true);
          setLoadingDelete(false);
          console.error('error', error);
        }
      }
    } else {
      setOpenWarning(true);
    }

    setLoadingDelete(false);
  };

  if (refresh) {
    setRefresh(false);
  }

  return (
    <div>
      <Head title="Sonar | Certificados" />

      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: '100%' }}
        >
          Sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Erro no envio!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openEmpty}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Certificado, Senha e ao menos uma filial não pode estar em branco!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openWarning}
        autoHideDuration={4000}
        onClose={handleCloseWarning}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseWarning}
          severity="warning"
          sx={{ width: '100%' }}
        >
          Dados faltante!
        </Alert>
      </Snackbar>

      <Header />
      <Stack className="stackContainer">
        <Divider
          style={{ color: '#252525', marginBottom: '20px' }}
          id="dividerCertificado"
        >
          Empresa
        </Divider>

        <FormControl
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InputLabel>Selecione uma empresa</InputLabel>
          <Select
            sx={{
              color: '#252525',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#2b2c5f',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2b2c5f',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2b2c5f',
              },
            }}
            displayEmpty
            value={empresaName}
            className="select"
            onChange={handleChange}
            input={<OutlinedInput label="Selecione uma empresa" />}
            // renderValue={(selected) => {
            //   if (selected.length === 0) {
            //     return <em></em>;
            //   }

            //   return selected.join(', ');
            // }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {/* <MenuItem disabled value="">
              <em>Selecione uma empresa</em>
            </MenuItem> */}
            {empresaGet.map((name) => (
              <MenuItem
                key={name.CNPJ}
                value={`${name.CNPJ} - ${name.RAZAOSOCIAL}`}
              >
                {`${name.CNPJ} - ${name.RAZAOSOCIAL}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {cnpjCheck ? (
          <div>
            <Divider style={{ color: '#252525' }} id="dividerCertificado">
              Certificado
            </Divider>
            <ThemeProvider theme={tema}>
              <Stack
                direction="flex"
                alignItems="center"
                justifyContent="center"
                className="stackBtn"
              >
                {file ? (
                  <Button
                    variant="contained"
                    component="label"
                    endIcon={<CheckIcon fontSize="small" />}
                    color="neutral"
                    disabled
                    sx={{ fontFamily: 'Calibri', marginRight: '1rem' }}
                  >
                    Selecionado
                    <input
                      hidden
                      accept=".pfx"
                      type="file"
                      onChange={handleCert}
                    />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    component="label"
                    color="neutral"
                    sx={{
                      fontFamily: 'Calibri',
                      marginRight: '1rem',
                      paddingLeft: 3,
                      paddingRight: 3,
                    }}
                  >
                    Certificado
                    <FingerprintIcon fontSize="small" />
                    <input
                      hidden
                      accept=".pfx"
                      type="file"
                      onChange={handleCert}
                    />
                  </Button>
                )}
                {file ? (
                  <input
                    type="password"
                    placeholder="Senha Certificado"
                    id="senhaCertificado"
                    onBlur={handleCryptPass}
                    required
                    autoFocus="true"
                    onChange={(e) => setCertPass(e.target.value)}
                  ></input>
                ) : (
                  ''
                )}
              </Stack>
            </ThemeProvider>
            <Divider style={{ color: '#252525' }} id="dividerCertificado">
              Filiais
            </Divider>
            <ThemeProvider
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TableContainer
                // component={Paper}
                // style={{
                //   width: '97%',
                // }}
                >
                  <Table size="small" padding={'none'}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width="5%"></TableCell>
                        <TableCell align="center">Status Certificado</TableCell>
                        <TableCell align="center">CNPJ</TableCell>
                        <TableCell align="center">Nome Filial</TableCell>
                        <TableCell align="center">
                          N° de série do certificado
                        </TableCell>
                        <TableCell align="center">
                          Validade do Certificado
                        </TableCell>
                        <TableCell align="center">Usuário</TableCell>
                        <TableCell align="center" width="10%">
                          Inclusão
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? certificate.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                          )
                        : certificate
                      ).map((item, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell
                              align="center"
                              sx={{ paddingRight: 1, paddingLeft: 1 }}
                            >
                              <input
                                type="checkbox"
                                name="selecionarFilial"
                                id="selecionarFilial"
                                value={item.CNPJ}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setSelectedSubs([
                                      ...selectedSubs,
                                      event.target.value,
                                    ]);
                                  } else {
                                    setSelectedSubs(
                                      selectedSubs.filter(
                                        (value) => value !== event.target.value,
                                      ),
                                    );
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell align="center" sx={{ paddingRight: 1 }}>
                              {(item.EXPIRATIONDATE) ? compareDates(item.EXPIRATIONDATE) : null}
                            </TableCell>
                            <TableCell
                              align="center"
                              width="150px"
                              sx={{ paddingRight: 1 }}
                            >
                              {item.CNPJ.replace(/\D/g, '').replace(
                                /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
                                '$1.$2.$3/$4-$5',
                              )}
                            </TableCell>
                            <TableCell align="center" sx={{ paddingRight: 1 }}>
                              {item.RAZAOSOCIAL}
                            </TableCell>
                            <TableCell align="center" sx={{ paddingRight: 1 }}>
                              {item.SERIALNUMBER}
                            </TableCell>
                            <TableCell align="center" sx={{ paddingRight: 1 }}>
                              {item.EXPIRATIONDATE}
                            </TableCell>
                            <TableCell align="center" sx={{ paddingRight: 1 }}>
                              {item.CREATEDBY}
                            </TableCell>
                            <TableCell align="center" sx={{ paddingRight: 1 }}>
                              {item.CREATEDON}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {/* <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'Todas', value: -1 },
                    ]}
                    colSpan={3}
                    count={certificate.length}
                    rowsPerPage={rowsPerPage}
                    component="div"
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> */}
                </TableContainer>
              </div>
            </ThemeProvider>

            <ThemeProvider theme={tema}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginBottom: '1rem',
                }}
              >
                <div id="btnSalvarFiliais">
                  <Button
                    variant="contained"
                    endIcon={<SaveIcon />}
                    color="neutral"
                    onClick={handleSubmitCert}
                    disabled={loadingSave}
                  >
                    Salvar
                  </Button>
                </div>
                <div id="btnSalvarFiliais">
                  <Button
                    variant="contained"
                    endIcon={<DeleteIcon />}
                    color="error"
                    sx={{ ml: 2 }}
                    onClick={handleDeleteCert}
                    disabled={loadingDelete}
                  >
                    Excluir
                  </Button>
                </div>
              </div>
            </ThemeProvider>
          </div>
        ) : (
          ''
        )}
      </Stack>
    </div>
  );
}

export default Certificados;
