import { React, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Pool from '../UserPool';
import './ForgotPassword.css';
import { time } from 'highcharts';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [stage, setStage] = useState(1); // 1 email stage - 2 code stage
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const getUser = () => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool,
    });
  };

  const sendCode = (event) => {
    event.preventDefault();

    getUser().forgotPassword({
      onSucess: (data) => {
      // console.log(data)
      },
      onFailure: (err) => {
    //  console.log(err)
      },
      inputVerificationCode: (data) => {
        setStage(2);
      },
    });
  };

  const resetPassword = (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      let message = document.querySelector('.messageError');
      message.innerText = 'As senhas não são iguais! Digite novamente. ⚠️';
      setTimeout(() => {
        message.innerText = '';
      }, 5000);

      return message;
    }

    // valida se a senha tem 8 caracteres, letras e números e caracteres especiais
    let regex = /^(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!regex.test(password)) {
      let message = document.querySelector('.messageError');
      message.innerText =
        'A senha deve conter pelo menos 8 caracteres, incluindo pelo menos 1 letra maiúscula, 1 número e \n 1 caractere especial (@, $, !, %, *, ?, &). ⚠️';
      setTimeout(() => {
        message.innerText = '';
      }, 7000);

      return message;
    }


    getUser().confirmPassword(code, password, {
      onSuccess: (data) => {
        let messagePassOk = document.querySelector('.messagePassOk');
        messagePassOk.innerText = 'Sua senha foi alterada corretamente!';
        setTimeout(() => {
          window.location.href = '/login';
        }, 3500);
      },
      onFailure: (err) => {
        console.error('nao deu certo ', err);
      },
    });
  };
  return (
    <div className="containerInputs">
      {stage === 1 && (
        <form onSubmit={sendCode} className="formulario">
          <input
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder="email"
            required
            id="emailReset"
          />
          <button type="submit" id="botaoReset">
            Enviar Código de Verificação
          </button>
        </form>
      )}
      {stage === 2 && (
        <form onSubmit={resetPassword} className="formForgotPass">
          <input
            value={code}
            onChange={(event) => setCode(event.target.value)}
            placeholder="código"
            required
            id="emailReset"
            className="codigoReset"
          />
          <input
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="digite sua senha"
            type="password"
            required
            id="emailReset"
            className="codigoReset"
          />
          <input
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            placeholder="digite novamente"
            type="password"
            required
            id="emailReset"
            className="codigoReset"
          />
          <p className="messageError"></p>

          <p className="messagePassOk"></p>

          <button type="submit" id="botaoReset">
            Alterar Senha
          </button>
        </form>
      )}

      <a href="/login" className="linkVoltarLogin">
        Voltar para página de Login
      </a>
    </div>
  );
};

export default ForgotPassword;
