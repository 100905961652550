import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import ColumnChart from 'highcharts-react-official';
import { useMediaQuery } from '@mui/material';
import { API_BASE, API_URL } from '../API/apiManager';

const ColumnChartAvancada = ({ dateStart, dateEnd }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [byUser, setByUser] = useState([]);

  const headers = {
    startdate: dateStart,
    enddate: dateEnd,
    base: API_BASE,
  };
  useEffect(() => {
    const fetchByUser = async () => {
      const response = await fetch(API_URL + '/dashboard/pj/byuser', {
        method: 'GET',
        headers: headers,
      });
      const data = await response.json().then((result) => {
        setByUser(result);
      });
    };
    fetchByUser();
  }, []);

  const typeAdvanced = byUser.filter((item) => item.TYPE === 'ADVANCEDSEARCH');

  Highcharts.setOptions({
    colors: ['#2b2c5f', '#9b2c5e', '#572E56'],
  });

  const options = {
    chart: {
      type: 'column',
      width: isMobile ? 330 : 800,
    },
    title: {
      text: 'Consultas Avançadas por Usuário',
      style: {
        color: '#2b2c5f',
        fontFamily: 'Calibri',
      },
    },
    xAxis: {
      categories: ['Consultas Avançadas por Usuário'],
      crosshair: true,
    },

    yAxis: {
      title: {
        text: 'Consultas Básicas por Usuário',
      },
    },

    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: typeAdvanced.map((item) => ({
      name: [item.CREATEDBY],
      data: [item.TOTAL],
    })),
  };

  return (
    <div>
      {' '}
      <ColumnChart highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ColumnChartAvancada;
