import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Head from './Head';
import Header from './Header';
import NavBar from './navBar';
import { API_BASE, API_URL } from './API/apiManager';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactSelect from 'react-select';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import './Orgaos.css';
import MuiAlert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import {
  Select,
  FormControl,
  MenuItem,
  Stack,
  Divider,
  Table,
  TableRow,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  ThemeProvider,
  Box,
  createTheme,
  Button,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

const tema = createTheme({
  palette: {
    neutral: {
      main: '#2b2c5f',
      contrastText: '#fff',
    },
    typography: {
      fontFamily: 'Calibri',
    },
  },
});

const styleModal = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 1500,
  minWidth: 350,
  height: 'auto',
  bgcolor: 'background.paper',
  color: '#252525',
  border: '1px solid #2b2c5f',
  boxShadow: 24,
  p: 4,
  zIndex: 300000,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? '#252525' : '#252525',
    opacity: 100,
    zIndex: 10000,
    borderColor: state.isHover ? '#2b2c5f' : '#2b2c5f',
    backgroundColor: state.isSelected ? '#ECF2FF' : '#eee',
  }),

  control: (defaultStyles, state) => ({
    ...defaultStyles,
    padding: '9px',
    borderColor: state.isSelected ? ' #2b2c5f' : '#252525',
    borderColor: state.isFocused ? ' #2b2c5f' : '#252525',
    borderColor: state.isHover ? '#2b2c5f' : '#2b2c5f',
    boxShadow: 'none',
  }),
};

function Orgaos() {
  const [empresaGet, setEmpresaGet] = useState([]);
  const [empresaName, setEmpresaName] = useState([]);
  const [agency, setAgency] = useState([]);
  const [user, setUser] = useState(sessionStorage.getItem('userSession'));
  const [value, setValue] = useState(null);
  const [filial, setFilial] = useState([]);
  const [CNPJ, setCNPJ] = useState('');
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [orgao, setOrgao] = useState([]);
  const [selectedValueOrgao, setSelectedValueOrgao] = useState([]);
  const [empresaId, setEmpresaId] = useState('');

  const [openError, setOpenError] = React.useState(false);
  const [openEmpty, setOpenEmpty] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
    setOpenEmpty(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenWarning(false);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setEmpresaName(typeof value === 'string' ? value.split(',') : value);
    const cnpj = value.substring(6, 20);
    const idCompany = value
      .slice(value.length - 4)
      .replace(' ', '')
      .replace(':', '');
    getAgenciesCompanies(cnpj);
    setCNPJ(cnpj);
    setEmpresaId(idCompany);
  };

  useEffect(() => {
    axios
      .get(API_URL + '/companies', {
        headers: {
          Base: API_BASE,
        },
      })
      .then((response) => {
        setEmpresaGet(response.data);
      });
  }, []);

  async function getAgenciesCompanies(cnpj) {
    try {
      const header = {
        Base: API_BASE,
        CNPJCompany: cnpj,
      };
      const res = await axios.get(API_URL + '/agencies/agenciescompany', {
        headers: header,
      });
      const result = res.data;
      setAgency(result);
    } catch (err) {
      //  console.log(err)
    }
  }

  const handleChangeClick = (e) => {
    setValue(e.target.value);
  };

  const getData = () => {
    const arr = [];
    axios
      .get(API_URL + '/subsidiaries/bycnpj', {
        headers: {
          Base: API_BASE,
          CNPJCompany: CNPJ,
        },
      })
      .then((res) => {
        let result = res.data;
        result.map((filial) => {
          return arr.push({
            value: filial.IDSUBSIDIARY,
            label: `${filial.CNPJFILIAL} - ${filial.RAZAOSOCIAL}`,
          });
        });
      });
    setFilial(arr);
  };

  const handleOpen = () => {
    setOpen(true);
    getData();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeFilial = (selectedOption) => {
    setSelected(selectedOption.value);
  };
  
  useEffect(() => {
    async function orgaos() {
      try {
        const arr = [];
        const r = await axios.get(API_URL + '/agencies', {
          headers: { Base: API_BASE },
        });
        let res = await r.data;
        res.map((agency) => {
          return arr.push({
            value: agency.IDAGENCY,
            label: agency.AGENCYNAME,
          });
        });
        setOrgao(arr);
      } catch (err) {
        // console.log(err);
      }
    }
    orgaos();
  }, []);

  const handlChangeOrgao = (selectedOption) => {
    setSelectedValueOrgao(selectedOption.value);
  };

  const handlePostOrgaos = async () => {
    const headers = {
      Base: API_BASE,
      idcompany: empresaId,
      idagency: selectedValueOrgao,
      user: user,
      idsubsidiary: selected,
    };

    const options = {
      method: 'POST',
      headers: headers,
      url: API_URL + '/agencies/agenciescompany',
      redirect: 'follow',
    };

    try {
      const response = await axios(options);
      console.log(response);
      if (response.data.statusCode === 200) {
        setOpenSuccess(true);
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      }
    } catch (err) {
      setOpenError(true);
      // console.log(err);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    let myHeaders = new Headers();
    myHeaders.append('idpagency', value);
    myHeaders.append('Base', API_BASE);

    let requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    };
    fetch(
      API_URL + '/agencies/agenciescompany',
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.statusCode === 200) {
          setOpenSuccess(true);
          setTimeout(() => {
            window.location.reload(true);
          }, 1500);
        } else {
          setOpenWarning(true);
        }
      })
      .catch((error) => {
        setOpenError(true);
      });
  };

  return (
    <>
      <Head title="Sonar | Órgãos" />
      <Header />
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: '100%' }}
        >
          Sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Erro no envio!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openEmpty}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Certificado, Senha e ao menos uma filial não pode estar em branco!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openWarning}
        autoHideDuration={4000}
        onClose={handleCloseWarning}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseWarning}
          severity="warning"
          sx={{ width: '100%' }}
        >
          Dados faltante!
        </Alert>
      </Snackbar>
      <Stack className="stackContainer">
        <Divider
          style={{ color: '#252525', marginBottom: '20px' }}
          id="dividerCertificado"
        >
          Empresa
        </Divider>
        <FormControl
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InputLabel>Selecionar Empresa</InputLabel>
          <Select
            sx={{
              color: '#252525',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#2b2c5f',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2b2c5f',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2b2c5f',
              },
            }}
            className="select"
            labelId="select-demo"
            id="empresas-select"
            value={empresaName}
            onChange={handleChange}
            MenuProps={MenuProps}
            input={<OutlinedInput label="Selecionar Empresa" />}
          >
            {empresaGet.map((empresa, i) => {
              return (
                <MenuItem
                  key={empresa.IDCOMPANY}
                  value={`CNPJ: ${empresa.CNPJ} - Empresa: ${empresa.RAZAOSOCIAL} - ID: ${empresa.IDCOMPANY} `}
                >
                  {`${empresa.CNPJ} - ${empresa.RAZAOSOCIAL}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {CNPJ ? (
          <div style={{ overflowY: 'scroll' }}>
            <Divider
              style={{
                color: '#252525',
                marginBottom: '1rem',
                marginTop: '1.2rem',
              }}
            >
              Órgãos
            </Divider>
            <div className="tableContainerOrgaos">
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ width: '5%' }}
                      ></TableCell>
                      <TableCell align="center" width="150px">
                        CNPJ
                      </TableCell>
                      <TableCell align="center">Nome</TableCell>
                      <TableCell align="center">UF</TableCell>
                      <TableCell align="center">Usuário</TableCell>
                      <TableCell align="center">Data de Inclusão</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {agency.map((item, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell align="center">
                            <input
                              value={item.IDPAGENCY}
                              onChange={handleChangeClick}
                              type="checkbox"
                              name="selecionarFilial"
                              id="selecionarFilial"
                            />
                          </TableCell>
                          <TableCell align="center" width="150px">
                            {item.CNPJ.replace(/\D/g, '').replace(
                              /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
                              '$1.$2.$3/$4-$5',
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {item.AGENCYNAME}
                          </TableCell>
                          <TableCell align="center">{item.UF}</TableCell>
                          <TableCell align="center">{item.CREATEDBY}</TableCell>
                          <TableCell align="center">{item.CREATEDON}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        ) : (
          ''
        )}
        {CNPJ ? (
          <div>
            {' '}
            <ThemeProvider theme={tema}>
              <div
                className="botoesParamsOrgaos"
                id="btnSalvarFiliais"
                style={{
                  gap: '10px',
                  marginBottom: '1rem',
                }}
              >
                <Button
                  variant="contained"
                  endIcon={<AddIcon />}
                  color="neutral"
                  onClick={handleOpen}
                >
                  Vincular
                </Button>
                <Button
                  variant="contained"
                  endIcon={<DeleteIcon />}
                  color="error"
                  onClick={handleDelete}
                >
                  Excluir
                </Button>
              </div>
            </ThemeProvider>
          </div>
        ) : (
          ''
        )}
      </Stack>
      <Modal open={open} onClose={handleClose}>
        <Box sx={styleModal}>
          <Divider
            style={{
              color: '#252525',
              marginBottom: '1rem',
            }}
          >
            Filial
          </Divider>
          <ReactSelect
            styles={customStyles}
            options={filial}
            onChange={handleChangeFilial}
            placeholder="Selecione uma filial"
            noOptionsMessage={() => 'Filial não encontrada!'}
            getOptionLabel={(options) => options['label']}
          />
          <Divider
            style={{
              color: '#252525',
              marginBottom: '1rem',
              marginTop: '1rem',
            }}
          >
            Órgãos
          </Divider>
          <ReactSelect
            autosize={true}
            multiple
            options={orgao}
            onChange={handlChangeOrgao}
            styles={customStyles}
            placeholder="Selecione uma agência"
            noOptionsMessage={() => 'Agência não encontrada'}
            getOptionLabel={(options) => options['label']}
          />
          <ThemeProvider theme={tema}>
            <div id="btnSalvarFiliais" style={{ marginTop: '2rem' }}>
              <Button
                onClick={handlePostOrgaos}
                variant="contained"
                endIcon={<SaveIcon />}
                color="neutral"
              >
                Salvar
              </Button>
            </div>
          </ThemeProvider>
        </Box>
      </Modal>
    </>
  );
}
export default Orgaos;
