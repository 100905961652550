import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import CadastroEmailNotificacao from './CadastroEmailNotificacao';
import '../Notificações.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 900,
  minWidth: 310,
  maxHeight: 'auto',
  minHeight: 250,
  bgcolor: 'background.paper',
  color: '#252525',
  border: '1px solid #2b2c5f',
  boxShadow: 24,
  p: 4,
};

const styleButton = {
  color: '#fff',
  backgroundColor: '#2b2c5f',
  ':hover': {
    backgroundColor: '#3b4e82 ',
    color: '#fff',
    borderColor: '#2b2c5f',
  },
};

const ModalNotificacoes = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="containerButtonNovaNotif">
      <Button onClick={handleOpen} variant="contained" style={styleButton}>
        Nova Notificação <AddIcon fontSize="small" />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CadastroEmailNotificacao />
        </Box>
      </Modal>
    </div>
  );
};

export default ModalNotificacoes;
