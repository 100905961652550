import { Divider, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import './CadastroEmailNotificacao.css';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme } from '@mui/material';
import { green, yellow, red } from '@mui/material/colors';

import axios from 'axios';
import { API_BASE, API_URL } from './API/apiManager';

const theme = createTheme({
  palette: {
    primary: {
      main: green[500],
      contrastText: '#fff',
    },
    secondary: {
      main: yellow[600],
      contrastText: '#000',
    },
    warning: {
      main: red[500],
    },
    padrao: {
      main: '#0288d1',
    },
    texto: {
      main: '#252525',
    },
  },
  typography: {
    fontFamily: 'Calibri',
    fontSize: 14,
  },
});

const CadastroEmailNotificacao = () => {
  // pega o valor de telefone
  const [value, setValue] = useState();
  // pega o valor boolean dos niveis de importancia das msgs
  const [state, setState] = useState({
    baixo: false,
    medio: false,
    alto: false,
  });
  // pega o valor de email
  const [email, setEmail] = useState('');
  //função para dispara mensagem de cadastro ok ou não
  const [message, setMessage] = useState('');

  //state de notificação de sms e email para saber se marcou qual vai querer receber
  const [stateNotificacao, setStateNotificacao] = useState({
    sms: false,
    email: false,
  });

  useEffect(() => {
    window.sessionStorage.setItem('phone', value);
    window.sessionStorage.setItem('email', email);
  }, [value, email]);

  const savedPhone = window.sessionStorage.getItem('phone', value);
  const savedEmail = window.sessionStorage.getItem('email', email);
  //verifica se o valor de telefone digitado é válido.
  // if (value && isValidPhoneNumber(value.toString())) {
  //   console.log('Válido');
  // } else {
  // }

  //onchange dos níveis de importância das msgs
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  //onchage das mensagens e email saber se marcou ou n
  const handleChangeNotificacao = (event) => {
    setStateNotificacao({
      ...stateNotificacao,
      [event.target.name]: event.target.checked,
    });
  };
  //função para post com axios
  const handlePostNotificacoes = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Base: API_BASE,
        sendsms: stateNotificacao.sms,
        sendmail: stateNotificacao.email,
        email: email,
        phonenumber: value,
        low: state.baixo,
        medium: state.medio,
        high: state.alto,
      };
      const res = await axios.post(
        API_URL + '/notifications',
        {},
        {
          headers: headers,
        },
      );
      if (res.data.statusCode === 200) {
        setMessage('Notificação cadastrada com sucesso!');
        // window.location.reload(true);
      } else {
        setMessage(res.data.body.Mensagem);
      }
    } catch (err) {
      setMessage('Erro! Verifique os dados!');
    }
  };

  return (
    <>
      <ThemeProvider theme={theme} sx={{ fontFamily: 'Calibri' }}>
        <Divider>Cadastro</Divider>
        <p className="pNotif">
          Cadastre seu número de celular e/ou e-mail para recebimento de
          notificações
        </p>
        <form onSubmit={handlePostNotificacoes}>
          <div className="containerPhoneInput">
            <PhoneInput
              className="inputNotificao"
              international
              defaultCountry="BR"
              displayInitialValueAsLocalNumber={true}
              placeholder="+55 (99) 99999-9999"
              value={value || savedPhone}
              onChange={setValue}
            />
            <input
              type="email"
              id="inputEmailNotificacao"
              placeholder="Digite seu email"
              value={email || savedEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  name="sms"
                  checked={stateNotificacao.sms}
                  onChange={handleChangeNotificacao}
                  color="padrao"
                />
              }
              label="Receber notificação por SMS"
            />
            <FormControlLabel
              control={
                <Switch
                  name="email"
                  checked={stateNotificacao.email}
                  onChange={handleChangeNotificacao}
                  color="padrao"
                />
              }
              label="Receber notificação por e-mail"
            />
          </div>
          <Divider sx={{ mb: '2rem', mt: '1rem' }}>Notificações</Divider>
          <div className="containerSwitchNot">
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend" sx={{ mb: '2rem' }} color="texto">
                Qual grau de importância de notificação deseja receber?
              </FormLabel>
              <FormGroup className="switches">
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.baixo}
                      onChange={handleChange}
                      name="baixo"
                      theme={theme}
                      color="primary"
                    />
                  }
                  label="Baixo"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.medio}
                      onChange={handleChange}
                      name="medio"
                      theme={theme}
                      color="secondary"
                    />
                  }
                  label="Médio"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.alto}
                      onChange={handleChange}
                      name="alto"
                      theme={theme}
                      color="warning"
                    />
                  }
                  label="Alto"
                />
              </FormGroup>
            </FormControl>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '3rem',
            }}
          >
            <Button variant="contained" id="Button" type="submit">
              Salvar <SaveAltIcon />
            </Button>
          </div>
          <div
            className="message"
            style={{
              position: 'relative',
              top: '10px',
              fontSize: '1rem',
            }}
          >
            {message ? (
              <p style={{ color: 'green' }}>{message}</p>
            ) : (
              <p style={{ color: 'red' }}>{message}</p>
            )}
          </div>
        </form>
      </ThemeProvider>
    </>
  );
};

export default CadastroEmailNotificacao;
