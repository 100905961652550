import { Divider } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpIcon from '@mui/icons-material/Help';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import Tooltip from './Tooltip';
import { CircularProgress } from '@mui/material';
import { API_BASE, API_URL } from './API/apiManager';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './EditEmpresas.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EditEmpresas({ empresa }) {
  const [empresaEdit, setEmpresaEdit] = useState(empresa);
  const { register, setValue, setFocus } = useForm();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [openError, setOpenError] = React.useState(false);
  const [openEmpty, setOpenEmpty] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);

  const textoTooltip = (
    <p>
      Atenção: Ao ativar essa opção as mensagens serão automaticamente
      <br />
      marcadas como lidas em seus devidos órgãos e toda gestão será feita pelo
      Sonar!
    </p>
  );

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
    setOpenEmpty(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenWarning(false);
  };
  // console.log(empresaEdit);

  function RemoverCaracteres(value) {
    return value.normalize('NFD').replace(/[^a-zA-Z0-9]/g, '');
  }
  function RemoverAcentos(value) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const headers = {
      Base: API_BASE,
      CNPJCOMPANY: empresaEdit.CNPJ,
      RAZAOSOCIAL: RemoverAcentos(empresaEdit.RAZAOSOCIAL),
      INSCRICAOESTADUAL: RemoverCaracteres(empresaEdit.INSCRICAOESTADUAL),
      INSCRICAOMUNICIPAL: RemoverCaracteres(empresaEdit.INSCRICAOMUNICIPAL),
      ATIVO: empresaEdit.ATIVO,
      CEP: empresaEdit.CEP,
      ENDERECO: RemoverCaracteres(empresaEdit.ENDERECO),
      NUMENDERECO: RemoverCaracteres(empresaEdit.NUMENDERECO),
      COMPLENDERECO: RemoverCaracteres(empresaEdit.COMPLENDERECO),
      BAIRRO: RemoverCaracteres(empresaEdit.BAIRRO),
      CIDADE: RemoverCaracteres(empresaEdit.CIDADE),
      MANAGEMESSAGES: empresaEdit.MANAGEMESSAGES,
      UF: RemoverCaracteres(empresaEdit.UF),
    };

    const res = await axios.put(
      API_URL + '/companie',
      {},
      {
        headers: headers,
      },
    );
    if (res.status === 200) {
      setOpenSuccess(true);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } else {
      setOpenError(true);
    }
  }

  const toggleHandler = (event) => {
    setEmpresaEdit({ ...empresaEdit, ATIVO: event.target.checked });
  };

  const handlerSwitch = (event) => {
    setEmpresaEdit({ ...empresaEdit, MANAGEMESSAGES: event.target.checked });
  };

  const checkCEP = (event) => {
    event.preventDefault();
    const cep = event.target.value.replace(/\D/g, '');
    if (!event.target.value) return;
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        const updatedEmpresaEdit = { ...empresaEdit };
        updatedEmpresaEdit.CEP = data.cep;
        updatedEmpresaEdit.ENDERECO = data.logradouro
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        updatedEmpresaEdit.COMPLENDERECO = data.complemento
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        updatedEmpresaEdit.CIDADE = data.localidade
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        updatedEmpresaEdit.BAIRRO = data.bairro
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        updatedEmpresaEdit.UF = data.uf;
        setEmpresaEdit(updatedEmpresaEdit);
      })
      .catch((error) => {
        console.log('Erro no Fetch, verifique: ', error);
      });
  };

  return (
    <section>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: '100%' }}
        >
          Empresa alterada com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Erro no envio!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openEmpty}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Certificado, Senha e ao menos uma filial não pode estar em branco!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openWarning}
        autoHideDuration={4000}
        onClose={handleCloseWarning}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseWarning}
          severity="warning"
          sx={{ width: '100%' }}
        >
          Dados faltante!
        </Alert>
      </Snackbar>
      <Divider>Editar dados de Empresa</Divider>
      <div id="formEmpresas">
        {loading ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              top: 100,
              zIndex: 10000,
            }}
          >
            <CircularProgress style={{ color: '#2b2c5f' }} />
          </Box>
        ) : (
          <form className="formEmpresas" onSubmit={handleSubmit}>
            <div className="divCNPJ">
              <InputMask
                mask="99.999.999/9999-99"
                required
                className="CNPJFormEdit"
                placeholder="CNPJ"
                disabled={true}
                value={empresaEdit.CNPJ}
                onChange={(e) =>
                  setEmpresaEdit({ ...empresaEdit, CNPJ: e.target.value })
                }
              />
              <input
                className="RazaoSocialFormEdit"
                type="text"
                placeholder="Razão Social"
                value={empresaEdit.RAZAOSOCIAL}
                onChange={(e) =>
                  setEmpresaEdit({
                    ...empresaEdit,
                    RAZAOSOCIAL: e.target.value,
                  })
                }
              />
            </div>
            <div
              className="divInscricoesFormEdit"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <input
                className="InscEstFormEdit"
                type="text"
                placeholder="Inscrição Estadual"
                value={empresaEdit.INSCRICAOESTADUAL}
                onChange={(e) =>
                  setEmpresaEdit({
                    ...empresaEdit,
                    INSCRICAOESTADUAL: e.target.value,
                  })
                }
              />
              <input
                className="InscMunFormEdit"
                type="text"
                placeholder="Inscrição Municipal"
                value={empresaEdit.INSCRICAOMUNICIPAL}
                onChange={(e) =>
                  setEmpresaEdit({
                    ...empresaEdit,
                    INSCRICAOMUNICIPAL: e.target.value,
                  })
                }
              />
              <input
                className="SimplesNacionaFomEdit"
                type="text"
                placeholder="Simples Nacional"
              />
            </div>

            <div className="SwitchEmpresaEdit">
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ 'aria-label': 'controlled' }}
                      value={empresaEdit.MANAGEMESSAGES}
                      checked={empresaEdit.MANAGEMESSAGES}
                      onChange={handlerSwitch}
                    />
                  }
                />
                <span className="textTooltip">
                  Deixar o Sonar gerenciar suas mensagens?
                </span>

                <Tooltip content={textoTooltip}>
                  <span className="iconTooltip">
                    <HelpIcon sx={{ color: '#2b2c5f' }} />
                  </span>
                </Tooltip>
              </div>
              <Stack direction="row" spacing={1} alignItems="center">
                Situação Empresa? {'  '}
                <FormControlLabel
                  control={
                    <Switch
                      value={empresaEdit.ATIVO}
                      checked={empresaEdit.ATIVO}
                      onChange={toggleHandler}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              </Stack>
            </div>
            <Divider>Endereço</Divider>
            <div className="divEnderecoCompletoEdit">
              <div className="divCepEdit">
                <InputMask
                  className="CEPFormEdit"
                  onBlur={checkCEP}
                  mask="99999-999"
                  type="text"
                  name="cep"
                  placeholder="CEP"
                  value={empresaEdit.CEP}
                  onChange={(e) =>
                    setEmpresaEdit({ ...empresaEdit, CEP: e.target.value })
                  }
                />
              </div>

              <div className="divEnderecoEdit">
                <input
                  className="EnderecoFormEdit"
                  type="text"
                  name="rua"
                  placeholder="Endereço"
                  id="inputEndereco"
                  value={empresaEdit.ENDERECO}
                  onChange={(e) =>
                    setEmpresaEdit({ ...empresaEdit, ENDERECO: e.target.value })
                  }
                />
                <input
                  className="NumFormEdit"
                  type="text"
                  name="numero"
                  placeholder="Nº"
                  id="inputEndereco"
                  value={empresaEdit.NUMENDERECO}
                  {...register('addressNumber')}
                  onChange={(e) =>
                    setEmpresaEdit({
                      ...empresaEdit,
                      NUMENDERECO: e.target.value,
                    })
                  }
                />
              </div>
              <div className="enderecoCompletoFormEdit">
                <div className="divComplementoEdit">
                  <input
                    className="CompleFormEdit"
                    type="text"
                    name="complemento"
                    placeholder="Complemento"
                    id="inputEndereco"
                    value={empresaEdit.COMPLENDERECO}
                    {...register('complemento')}
                    onChange={(e) =>
                      setEmpresaEdit({
                        ...empresaEdit,
                        COMPLENDERECO: e.target.value,
                      })
                    }
                  />
                  <input
                    className="BairroFormEdit"
                    type="text"
                    name="bairro"
                    placeholder="Bairro"
                    id="inputEndereco"
                    value={empresaEdit.BAIRRO}
                    {...register('neighborhood')}
                    onChange={(e) =>
                      setEmpresaEdit({ ...empresaEdit, BAIRRO: e.target.value })
                    }
                  />
                </div>
                <div className="divCidadeEdit">
                  <input
                    className="CidadeFormEdit"
                    type="text"
                    name="cidade"
                    placeholder="Cidade"
                    id="inputEndereco"
                    value={empresaEdit.CIDADE}
                    {...register('city')}
                    onChange={(e) =>
                      setEmpresaEdit({ ...empresaEdit, CIDADE: e.target.value })
                    }
                  />
                  <input
                    className="UFFormEdit"
                    type="text"
                    name="uf"
                    placeholder="UF"
                    id="inputEndereco"
                    value={empresaEdit.UF}
                    {...register('uf')}
                    onChange={(e) =>
                      setEmpresaEdit({ ...empresaEdit, UF: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="buttonSalvarEdit">
              <Button variant="contained" id="Button" type="submit">
                Salvar <SaveAltIcon />
              </Button>
            </div>
          </form>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '0.55rem',
          }}
        >
          {message ? (
            <p style={{ color: 'green' }}>{message}</p>
          ) : (
            <p style={{ color: 'red' }}>{message}</p>
          )}
        </div>
      </div>
    </section>
  );
}
