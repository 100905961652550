import React, { useEffect, useState } from 'react';
import './styles.css';
import dayjs, { locale } from 'dayjs';
import { FormHelperText, Paper, Typography } from '@mui/material';
import ConfigProvider from 'antd/lib/config-provider';
import 'dayjs/locale/pt-br';
import ptBR from 'antd/lib/locale/pt_BR';
import Header from '../Header';
import CardConsulta from './CardConsulta';
import PizzaChart from '../PizzaChart/PizzaChart';
import { DatePicker } from 'antd';
import TableConsultas from '../TableConsultas/TableConsultas';
import { Box, CircularProgress } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { API_BASE, API_URL } from '../API/apiManager';
import TabsChart from './TabsChart';
const { RangePicker } = DatePicker;

const RelatoriosCNPJ = () => {
  const [dateStart, setDateStart] = useState(
    dayjs().startOf('year').format('YYYY-MM-DD'),
  );
  const [dateEnd, setDateEnd] = useState(dayjs().format('YYYY-MM-DD'));
  const [consultas, setConsultas] = useState([]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [consultasTotal, setConsultasTotal] = useState([]);
  const [consultasBasicas, setConsultasBasicas] = useState([]);
  const [consultasAvancadas, setConsultasAvancadas] = useState([]);

  var myHeaders = {
    startdate: dateStart,
    base: API_BASE,
    enddate: dateEnd,
  };
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  function handleDate(date, dateString) {
    const formatDate = (date) => {
      const partes = date.split('/');

      if (partes.length === 3) {
        return `${partes[2]}-${partes[1]}-${partes[0]}`;
      }
      return 'Data Inválida';
    };
    setDateStart(formatDate(dateString[0]));
    setDateEnd(formatDate(dateString[1]));
  }
  useEffect(() => {
    if (dateStart && dateEnd) {
      PJConsumes(dateStart, dateEnd);
    }
  }, [dateStart, dateEnd]);
  console.log(dateStart, dateEnd);
  function PJConsumes(dateStart, dateEnd) {
    setLoading(true);
    const response = fetch(API_URL + '/reports/pjreports/pjconsumes', {
      method: 'GET',
      headers: {
        startdate: dateStart,
        base: API_BASE,
        enddate: dateEnd,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setConsultas(result);
        setLoading(false);
      })
      .catch((error) => console.log('error', error));
  }

  const ConsultasTotais = fetch(API_URL + '/dashboard/pj/all', requestOptions)
    .then((response) => response.json())
    .then((result) => {
      setConsultasTotal(result[0]?.COUNT);
      setConsultasBasicas(result[1]?.COUNT);
      setConsultasAvancadas(result[2]?.COUNT);
    })
    .catch((error) => console.log('error', error));

  // console.log(dateStart, dateEnd);
  return (
    <>
      <Header />
      <div className="containerTotal">
        <div className="container" style={{ alignItems: 'center' }}>
          <h3
            className="h3Empresa"
            style={{ color: '#2b2c5f', textAlign: 'center', marginBottom: 10 }}
          >
            Relatórios de Consultas CNPJ
          </h3>

          <div className="datePickers">
            <ConfigProvider locale={ptBR}>
              {' '}
              <RangePicker
                allowClear={true}
                placeholder={['Data Inicial', 'Data Final']}
                format="DD/MM/YYYY"
                onChange={handleDate}
                views={['day', 'month', 'year']}
                size="large"
                style={{
                  width: isMobile ? 300 : 600,
                  height: 48,
                }}
                className="antdPicker"
              />
            </ConfigProvider>
          </div>
          {loading ? (
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <CircularProgress sx={{ color: '#2b2c5f' }} />
            </Box>
          ) : (
            <div>
              <div className="cardsConsultas">
                <CardConsulta>
                  <div className="divTypo">
                    {' '}
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      sx={{ textAlign: 'center' }}
                      component="div"
                    >
                      Consulta Básica
                    </Typography>
                  </div>
                  <Typography
                    gutterBottom
                    variant="h2"
                    component="div"
                    style={{ color: '#9b2c5e' }}
                  >
                    {consultasBasicas}
                  </Typography>
                  <FormHelperText style={{ textAlign: 'center' }}>
                    Total de Consultas Básicas
                  </FormHelperText>
                </CardConsulta>
                <CardConsulta>
                  <div className="divTypo">
                    {' '}
                    <Typography
                      gutterBottom
                      sx={{ textAlign: 'center' }}
                      variant="subtitle1"
                      component="div"
                    >
                      Consulta Avançada
                    </Typography>
                  </div>
                  <Typography
                    gutterBottom
                    variant="h2"
                    component="div"
                    style={{ color: '#9b2c5e' }}
                  >
                    {consultasAvancadas}
                  </Typography>
                  <FormHelperText style={{ textAlign: 'center' }}>
                    Total de Consultas Avançadas
                  </FormHelperText>
                </CardConsulta>
                <CardConsulta>
                  <div className="divTypo">
                    {' '}
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                      sx={{ textAlign: 'center' }}
                    >
                      Total de Consultas
                    </Typography>
                  </div>
                  <Typography
                    gutterBottom
                    variant="h2"
                    component="div"
                    style={{ color: '#9b2c5e' }}
                  >
                    {consultasTotal}
                  </Typography>
                  <FormHelperText style={{ textAlign: 'center' }}>
                    Total de Consultas
                  </FormHelperText>
                </CardConsulta>
              </div>

              <div className="chartPizza">
                <PizzaChart
                  consultasAvancadas={consultasAvancadas}
                  consultasBasicas={consultasBasicas}
                />{' '}
              </div>
              <div className="tableConsultas">
                <TableConsultas consultas={consultas} />
              </div>
              <div className="chartPizza">
                <TabsChart dateStart={dateStart} dateEnd={dateEnd} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RelatoriosCNPJ;
