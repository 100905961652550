import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import EditEmpresas from './EditEmpresas';
import EditFiliais from './EditiFiliais';
import './TabCadastros.css';

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#515276',
  900: '#2b2c5f',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const Tab = styled(TabUnstyled)`
  font-family: Calibri, monospace;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[800]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[800]};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: ${blue[900]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)(
  ({ theme }) => `
  max-width: 100%;
  min-width: 200px;
  max-height: 650px;
  font-family: Calibri;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  `,
);

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  min-width:400px;
  min-width: 100px;
  background-color: ${blue[900]};
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === 'dark' ? grey[900] : grey[100]
  };
  `,
);

export default function UnstyledTabsIntroduction({ empresa }) {
  return (
    <TabsUnstyled defaultValue={0}>
      <TabsList>
        <Tab>Editar Empresa</Tab>
        <Tab>Editar Filiais</Tab>
      </TabsList>
      <TabPanel value={0}>
        <EditEmpresas empresa={empresa} />
      </TabPanel>
      <TabPanel value={1}>
        <EditFiliais empresa={empresa} />
      </TabPanel>
    </TabsUnstyled>
  );
}
