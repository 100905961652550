import React from 'react';
import Data from './DataGrid';
import { Box } from '@mui/system';

export default function CadastroEmpresas() {
  return (
    <Box>
      <Data />
    </Box>
  );
}
