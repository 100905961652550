import React from 'react';
import Logo from '../LogoPollvo';
import './LoginPage.css';
import SonarLogo from '../imgs/sonarLogoBranco.png';

const LoginPage = () => {
  return (
    <>
      <div className="headerSite">
        <Logo />
        <p className="Radar">| Sonar</p>
        <img
          className="sonarLogo"
          src={SonarLogo}
          style={{
            marginTop: '-8px',
            marginLeft: '-15px',
          }}
        />
      </div>
    </>
  );
};

export default LoginPage;
