import React, { useState, useEffect } from 'react';
import Head from './Head';
import Header from './Header';
import NavBar from './navBar';
import AddIcon from '@mui/icons-material/Add';
import {
  Stack,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import './Orgaos.css';
import axios from 'axios';
import { Select } from '@mui/material';
import ReactSelect from 'react-select';
import { API_BASE, API_URL } from './API/apiManager';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './ParametrizacaoNotificacoes.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={3} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const styleSelectEmpresas = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? '#252525' : '#252525',
    opacity: 100,
    zIndex: 10000,
    borderColor: state.isHover ? '#2b2c5f' : '#2b2c5f',
    backgroundColor: state.isSelected ? '#ECF2FF' : '#eee',
  }),

  control: (defaultStyles, state) => ({
    ...defaultStyles,
    padding: '9px',
    borderColor: state.isSelected ? ' #2b2c5f' : '#252525',
    borderColor: state.isFocused ? ' #2b2c5f' : '#252525',
    borderColor: state.isHover ? '#2b2c5f' : '#2b2c5f',
    boxShadow: 'none',
    marginTop: '1rem',
  }),
};

const styleModal = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 1500,
  minWidth: 350,
  height: 180,
  bgcolor: 'background.paper',
  color: '#252525',
  border: '1px solid #2b2c5f',
  boxShadow: 24,
  p: 4,
  zIndex: 300000,
};

const tema = createTheme({
  palette: {
    neutral: {
      main: '#2b2c5f',
      contrastText: '#fff',
    },
    typography: {
      fontFamily: 'Calibri',
    },
  },
});

export default function ParametrizaçãoNotificação() {
  const [notificacoes, setNotificacoes] = useState([]);
  const [notificacaoName, setNotificacaoName] = useState([]);
  const [idNotificacao, setIdNotificacao] = useState([]);
  const [empresaNotif, setEmpresaNotif] = useState([]);
  const [value, setValue] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [empresaSelected, setEmpresaSelected] = useState(null);
  const [selecionado, setSelecionado] = useState('');
  const [open, setOpen] = useState(false);

  const [openError, setOpenError] = React.useState(false);
  const [openEmpty, setOpenEmpty] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={3} ref={ref} variant="filled" {...props} />;
  });

  // if (empresaNotif.length > 0) {
  //   console.log(empresaNotif, 'empresaNotif');
  // }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
    setOpenEmpty(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenWarning(false);
  };

  useEffect(() => {
    axios
      .get(API_URL + '/notifications', {
        headers: {
          Base: API_BASE,
        },
      })
      .then((response) => {
        //console.log(response);
        setNotificacoes(response.data);
      });
  }, []);

  console.log(selecionado, 'select');
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setNotificacaoName(typeof value === 'string' ? value.split(',') : value);
    setSelecionado(value);
    // console.log(notificacaoName, 'notificacaoName');
    // console.log(selecionado, 'selecionado');

    const IDNotificacao = value
      .substring(0, 3)
      .replace(' ', '')
      .replace('+', '');

    setIdNotificacao(IDNotificacao);

    axios
      .get(API_URL + '/notifications/notificationcompanies', {
        headers: { idnotification: IDNotificacao, Base: API_BASE },
      })
      .then((response) => {
        // console.log(response.data, 'response.data');
        setEmpresaNotif(response.data);
      });
  };
  // console.log(empresaNotif, 'empresaNotif');
  useEffect(() => {
    const getEmpresa = async () => {
      const array = [];
      axios
        .get(API_URL + '/companies', {
          headers: {
            Base: API_BASE,
          },
        })
        .then((res) => {
          let result = res.data;
          result.map((company) => {
            return array.push({
              value: company.IDCOMPANY,
              label: `${company.CNPJ} - ${company.RAZAOSOCIAL}`,
            });
          });
        });
      setEmpresas(array);
    };
    getEmpresa();
  }, []);

  const handleChangeEmpresas = (selectedOption) => {
    setEmpresaSelected(selectedOption.value);
  };

  const postNotificacaoEmpresa = async () => {
    let myHeaders = new Headers();
    myHeaders.append('Base', API_BASE);
    myHeaders.append('idcompany', empresaSelected);
    myHeaders.append('idnotification', idNotificacao);
    myHeaders.append('user', sessionStorage.getItem('userSession'));

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
    };
    console.log(requestOptions);
    await fetch(
      API_URL + '/notifications/notificationcompanies',
      requestOptions,
    )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setOpenSuccess(true);
          setTimeout(() => {
            window.location.reload(true);
          }, 1500);
        } else {
          setOpenWarning(true);
        }
      })
      .catch((error) => {
        setOpenError(true);
      });
  };

  const handleChangeClick = (e) => {
    setValue(e.target.value);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const headers = {
      Base: API_BASE,
      idpnotifications: value,
    };
    try {
      const options = {
        method: 'DELETE',
        headers: headers,
        redirect: 'follow',
      };
      fetch(API_URL + '/notifications/notificationcompanies', options).then(
        (response) => {
          if (response.status === 200) {
            setOpenSuccess(true);
            setTimeout(() => {
              window.location.reload(true);
            }, 1500);
          } else {
            setOpenWarning(true);
          }
        },
      );
    } catch (error) {
      setOpenError(true);
    }
  };

  return (
    <>
      <Head title="Sonar | Notificações" />
      <Header />
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: '100%' }}
        >
          Sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Erro no envio!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openEmpty}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Certificado, Senha e ao menos uma filial não pode estar em branco!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openWarning}
        autoHideDuration={4000}
        onClose={handleCloseWarning}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseWarning}
          severity="warning"
          sx={{ width: '100%' }}
        >
          Dados faltante!
        </Alert>
      </Snackbar>
      <Stack
        className="stackContainer"
        // sx={{
        //   backgroundColor: '#fff',
        //   width: 1200,
        //   minHeight: '175px',
        //   maxHeight: '750px',
        //   marginTop: '2rem',
        //   borderRadius: 2,
        // }}
      >
        <Divider
          style={{ color: '#252525', marginBottom: '20px' }}
          id="dividerCertificado"
        >
          Notificações
        </Divider>
        <FormControl
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InputLabel>Selecione uma notificação</InputLabel>
          <Select
            sx={{
              color: '#252525',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#2b2c5f',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2b2c5f',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2b2c5f',
              },
            }}
            className="select"
            value={notificacaoName}
            onChange={handleChange}
            MenuProps={MenuProps}
            input={<OutlinedInput label="Selecione uma notificação" />}
          >
            {/* <MenuItem disabled value="">
              <em>Selecione uma Notificação</em>
            </MenuItem> */}
            {notificacoes.map((notificacao, i) => {
              return (
                <MenuItem
                  key={notificacao.IDNOTIFICATION}
                  value={`${notificacao.IDNOTIFICATION} ${notificacao.PHONENUMBER} ${notificacao.EMAIL}`}
                >
                  {`CEL: ${notificacao.PHONENUMBER} - E-MAIL: ${notificacao.EMAIL}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {selecionado ? (
          <div className="scrollTableOrgaos">
            <Divider
              style={{
                color: '#252525',
                marginBottom: '1rem',
                marginTop: '1.2rem',
              }}
            >
              Empresas
            </Divider>
            <div>
              {empresaNotif ? (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center" style={{ display: 'none' }}>
                          IDNOTIFICATION
                        </TableCell>
                        <TableCell align="center">CNPJ</TableCell>
                        <TableCell align="center">Razão Social</TableCell>
                        <TableCell align="center">Usuário</TableCell>
                        <TableCell align="center">Data de Criação</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {empresaNotif.map((empresa, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell align="center">
                              <input
                                value={empresa.IDPNOTIFICATIONS}
                                type="checkbox"
                                name="selecionarFilial"
                                id="selecionarEmpresaDelete"
                                onChange={handleChangeClick}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ display: 'none' }}
                            >
                              IDNOTIFICATION
                            </TableCell>
                            <TableCell align="center">
                              {empresa.CNPJ.replace(/\D/g, '').replace(
                                /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
                                '$1.$2.$3/$4-$5',
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {empresa.RAZAOSOCIAL}
                            </TableCell>
                            <TableCell align="center">
                              {empresa.CREATEDBY}
                            </TableCell>
                            <TableCell align="center">
                              {empresa.CREATEDON}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                  <p style={{ color: 'black' }}>Nenhuma empresa vinculada</p>
                </div>
              )}
            </div>
          </div>
        ) : (
          ''
        )}
        {selecionado ? (
          <div
            className="botoesParamsOrgaos"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ThemeProvider theme={tema}>
              <Button
                variant="contained"
                endIcon={<AddIcon />}
                color="neutral"
                sx={{ m: 2 }}
                onClick={handleOpen}
              >
                Vincular
              </Button>
              <Button
                variant="contained"
                endIcon={<DeleteIcon />}
                color="error"
                onClick={handleDelete}
              >
                Excluir
              </Button>
            </ThemeProvider>
          </div>
        ) : (
          ''
        )}
        <Modal open={open} onClose={handleClose}>
          <Box sx={styleModal}>
            <Divider
              style={{
                color: '#252525',
              }}
            >
              Empresa
            </Divider>
            <ReactSelect
              options={empresas}
              styles={styleSelectEmpresas}
              onChange={handleChangeEmpresas}
              placeholder="Selecione uma empresa"
              noOptionsMessage={() => 'Empresa não encontrada!'}
            />
            <ThemeProvider theme={tema}>
              <div id="btnSalvarFiliais" style={{ marginTop: '2rem' }}>
                <Button
                  variant="contained"
                  endIcon={<SaveIcon />}
                  color="neutral"
                  onClick={postNotificacaoEmpresa}
                >
                  Salvar
                </Button>
              </div>
            </ThemeProvider>
          </Box>
        </Modal>
      </Stack>
    </>
  );
}
