const currentURL = window.location.href;

const API_URL = currentURL.includes('localhost') || currentURL.includes('demo')
  ? "https://api-sonar.pollvo.com/dev"
  : "https://api-sonar.pollvo.com/prod";

const API_BASE = currentURL.includes('localhost') || currentURL.includes('demo')
  ? "localhost"
  : currentURL.split(/[-.]/)[1];

export { API_URL, API_BASE };