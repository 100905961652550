import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import {
  Divider,
  Box,
  IconButton,
  Modal,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Stack,
} from '@mui/material';
import './DadosFiliais.css';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import './EditiFiliais.css';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Button from '@mui/material/Button';
import warning from '../Components/imgs/warning.svg';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { API_BASE, API_URL } from './API/apiManager';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const filialInicial = {
  IDSUBSIDIARY: '',
  RAZAOSOCIAL: '',
  CNPJFILIAL: '',
  INSCRICAOMUNICIPAL: '',
  ENDERECO: '',
  NUMENDERECO: '',
  COMPLENDERECO: '',
  BAIRRO: '',
  CIDADE: '',
  UF: '',
  CEP: '',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 250,
  color: '#252525',
  bgcolor: 'background.paper',
  border: '1px solid #2b2c5f',
  boxShadow: 24,
  borderRadius: '0.75rem',
  p: 4,
};

export default function EditFiliais({ empresa }) {
  const [filiais, setFiliais] = useState([{ ...filialInicial }]);
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [filial, setFilial] = useState('');
  const [loading, setLoading] = useState(false);
  const [filialPost, setFilialPost] = useState([]);
  const [value, setValue] = useState('');

  const [openError, setOpenError] = React.useState(false);
  const [openEmpty, setOpenEmpty] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    if (empresa.CNPJ) {
      axios
        .get(API_URL + '/subsidiaries/bycnpj', {
          headers: {
            Base: API_BASE,
            CNPJCompany: empresa.CNPJ,
          },
        })
        .then((response) => {
          setLoading(false);
          setFiliais(response.data);
          setFilialPost(response.data);
          // console.log(response.data);
        });
    }
  }, []);

  const handleClick = () => {
    setFiliais([
      ...filiais,
      { ...filialInicial, IDSUBSIDIARY: filiais.at(-1).IDSUBSIDIARY + 1 },
    ]);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
    setOpenEmpty(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenWarning(false);
  };

  const handleDelete = (index) => {
    // const newArray = [...filiais];
    // newArray.splice(index, 1);
    // setFiliais(newArray);
    setOpen(true);
    const novaArray = [...filiais];
    setFilial(novaArray[index].CNPJFILIAL);
  };

  const handleOnChange = (index, valueToChange, e) => {
    const novaArray = [...filiais];
    novaArray[index][valueToChange] = e.target.value;
    setFiliais(novaArray);
    setValue(novaArray[index]);
  };

  function RemoverCaracteres(value) {
    return value.normalize('NFD').replace(/[^a-zA-Z0-9]/g, '');
  }

  function RemoverAcentos(value) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  const handlePutFilial = async (e) => {
    e.preventDefault();
    if (filialPost.length == filiais.length) {
      const header = filiais.map(async (item) => {
        const headers = {
          Base: API_BASE,
          cnpjcompany: empresa.CNPJ,
          cnpjsubsidiary: item.CNPJFILIAL,
          razaosocial: RemoverAcentos(item.RAZAOSOCIAL),
          inscricaomunicipal: RemoverCaracteres(item.INSCRICAOMUNICIPAL),
          cep: item.CEP,
          endereco: RemoverCaracteres(item.ENDERECO),
          numendereco: RemoverCaracteres(item.NUMENDERECO),
          complendereco: RemoverCaracteres(item.COMPLENDERECO),
        };
        try {
          const res = await axios.put(
            API_URL + '/subsidiaries/bycnpj',
            {},
            {
              headers: headers,
            },
          );

          if (res.status === 200) {
            setOpenSuccess(true);
            // setTimeout(() => {
            //   setLoading(false);
            //   window.location.reload(true);
            // }, 2000);
          } else if (res.status !== 200) {
            setOpenError(true);
          }
        } catch (err) {
          setOpenError(true);
        }
      });
    } else {
      const headers = {
        Base: API_BASE,
        cnpjcompany: empresa.CNPJ,
        cnpjsubsidiary: value.CNPJFILIAL,
        razaosocial: value.RAZAOSOCIAL,
        endereco: RemoverCaracteres(value.ENDERECO),
        inscricaomunicipal: RemoverCaracteres(value.INSCRICAOMUNICIPAL),
        cep: value.CEP,
        numendereco: RemoverCaracteres(value.NUMENDERECO),
        complendereco: RemoverCaracteres(value.COMPLENDERECO),
        user: sessionStorage.getItem('userSession'),
      };
      try {
        const res = await axios.post(
          API_URL + '/subsidiaries/bycnpj',
          {},
          {
            headers: headers,
          },
        );

        if (res.status === 200) {
          setOpenSuccess(true);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1500);
        } else if (res.status !== 200) {
          setOpenError(true);
        }
      } catch (err) {
        setOpenError(true);
      }
    }
  };
  const handleDeleteSubsidiary = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append('cnpjsubsidiary', filial);
      myHeaders.append('Base', API_BASE);

      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow',
      };
      fetch(API_URL + '/subsidiaries/bycnpj', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.statusCode === 200) {
            setOpenSuccess(true);
            window.location.reload(true);
          } else {
            setOpenError(true);
          }
        })
        .catch((error) => console.log('error', error));
    } catch (err) {
      //  console.log(err)
    }
  };
  return (
    <div>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: '100%' }}
        >
          Sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Erro no envio!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openEmpty}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Certificado, Senha e ao menos uma filial não pode estar em branco!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openWarning}
        autoHideDuration={4000}
        onClose={handleCloseWarning}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseWarning}
          severity="warning"
          sx={{ width: '100%' }}
        >
          Dados faltante!
        </Alert>
      </Snackbar>
      <Divider>Editar Cadastro de Filial</Divider>
      <div
        className="containerTable"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Box>
          <IconButton onClick={handleClick} id="addInput">
            <AddIcon fontSize="inherit" />
          </IconButton>
          {loading ? (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                top: 30,
                zIndex: 10000,
              }}
            >
              <CircularProgress style={{ color: '#2b2c5f' }} />
            </Box>
          ) : (
            <form onSubmit={handlePutFilial}>
              <Stack className="stackFiliais">
                <TableContainer>
                  <Table className="tableScroll" size="small" padding="none">
                    <TableHead>
                      <TableRow id="labelTable">
                        <TableCell align="center" sx={{ color: '#fff' }}>
                          CNPJ
                        </TableCell>
                        <TableCell align="center" sx={{ color: '#fff' }}>
                          Razão Social
                        </TableCell>
                        <TableCell align="center" sx={{ color: '#fff' }}>
                          Insc. Municipal
                        </TableCell>
                        <TableCell align="center" sx={{ color: '#fff' }}>
                          CEP
                        </TableCell>
                        <TableCell align="center" sx={{ color: '#fff' }}>
                          Endereço
                        </TableCell>
                        <TableCell align="center" sx={{ color: '#fff' }}>
                          Número
                        </TableCell>
                        <TableCell align="center" sx={{ color: '#fff' }}>
                          Complemento
                        </TableCell>
                        <TableCell align="center" sx={{ color: '#fff' }}>
                          Ações
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filiais.length &&
                        filiais.map((item, index) => {
                          return (
                            <TableRow key={item.IDSUBSIDIARY}>
                              <TableCell>
                                <InputMask
                                  className={index}
                                  // mask="99.999.999/9999-99"
                                  placeholder="CNPJ Filial"
                                  style={{ width: '150px' }}
                                  id="inputFiliais"
                                  disabled={false}
                                  value={item.CNPJFILIAL || ' '}
                                  onChange={(e) =>
                                    handleOnChange(index, 'CNPJFILIAL', e)
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <input
                                  className={index}
                                  type="text"
                                  placeholder="Razão Social"
                                  style={{ width: '150px' }}
                                  id="inputFiliais"
                                  value={item.RAZAOSOCIAL || ' '}
                                  onChange={(e) =>
                                    handleOnChange(index, 'RAZAOSOCIAL', e)
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <input
                                  className={index}
                                  type="text"
                                  placeholder="Insc. Municipal"
                                  style={{ width: '100px' }}
                                  id="inputFiliais"
                                  value={item.INSCRICAOMUNICIPAL || ' '}
                                  onChange={(e) =>
                                    handleOnChange(
                                      index,
                                      'INSCRICAOMUNICIPAL',
                                      e,
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <InputMask
                                  className={index}
                                  mask="99999-999"
                                  type="text"
                                  name="cep"
                                  placeholder="CEP"
                                  style={{ width: '100px' }}
                                  id="inputFiliais"
                                  value={item.CEP || ' '}
                                  onChange={(e) =>
                                    handleOnChange(index, 'CEP', e)
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <input
                                  className={index}
                                  type="text"
                                  name="endereco"
                                  style={{ width: '70px' }}
                                  id="inputFiliais"
                                  value={item.ENDERECO || ' '}
                                  onChange={(e) =>
                                    handleOnChange(index, 'ENDERECO', e)
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <input
                                  className={index}
                                  type="text"
                                  name="numero"
                                  placeholder="Número"
                                  style={{ width: '70px' }}
                                  id="inputFiliais"
                                  value={item.NUMENDERECO || ' '}
                                  onChange={(e) =>
                                    handleOnChange(index, 'NUMENDERECO', e)
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <input
                                  className={index}
                                  type="text"
                                  name="complemento"
                                  placeholder="Complemento"
                                  style={{ width: '100px' }}
                                  id="inputFiliais"
                                  value={item.COMPLENDERECO || ' '}
                                  onChange={(e) =>
                                    handleOnChange(index, 'COMPLENDERECO', e)
                                  }
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ width: '70px' }}
                              >
                                <IconButton
                                  className={index}
                                  onClick={() => handleDelete(index)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
              <Modal open={open}>
                <Box sx={style}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <img src={warning} />
                  </div>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h1"
                    textAlign="center"
                  >
                    Cuidado!
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, mb: 2 }}
                    textAlign="center"
                  >
                    Deseja excluir esta{' '}
                    <b style={{ color: 'red' }}>FILIAL E SEUS DADOS</b>?
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      endIcon={<DeleteIcon />}
                      onClick={handleDeleteSubsidiary}
                      sx={{ mr: 2 }}
                      style={{ backgroundColor: 'green' }}
                    >
                      Sim, excluir agora!
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleClose}
                      style={{ backgroundColor: 'red' }}
                    >
                      Cancelar!
                    </Button>
                  </div>
                  {message ? (
                    <p
                      style={{
                        color: 'green',
                        textAlign: 'center',
                        marginTop: '1rem',
                      }}
                    >
                      {message}
                    </p>
                  ) : (
                    <p
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        marginTop: '1rem',
                      }}
                    >
                      {message}
                    </p>
                  )}
                </Box>
              </Modal>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  id="Button"
                  type="submit"
                  style={{ marginTop: '1rem' }}
                >
                  Salvar <SaveAltIcon />
                </Button>
              </div>
            </form>
          )}
        </Box>
      </div>
    </div>
  );
}
