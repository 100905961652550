import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import InboxIcon from '@mui/icons-material/Inbox';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import InventoryIcon from '@mui/icons-material/Inventory';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AssessmentIcon from '@mui/icons-material/Assessment';
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from 'react-pro-sidebar';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBarTop from '../Components/AppBar';
import { API_BASE } from './API/apiManager';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

function Teste({ logout, props }) {
  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken } =
    useProSidebar();

  const toggle = () => {
    toggleSidebar();
    if (toggled) {
      collapseSidebar();
    } else {
      collapseSidebar();
    }
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  function LocalHost() {
    if (
      API_BASE === 'localhost' ||
      API_BASE === 'innophos' ||
      API_BASE === 'rsm'
    ) {
      return (
        <div>
          <SubMenu label="Consultas" icon={<ContentPasteSearchIcon />}>
            <MenuItem
              icon={<SearchIcon />}
              component={<Link to="/home/consultas/consultaBasica" />}
            >
              Consulta Básica CNPJ
            </MenuItem>
            <MenuItem
              icon={<SavedSearchIcon />}
              component={<Link to="/home/consultas/consultaAvancada" />}
            >
              Consulta Avançada CNPJ
            </MenuItem>
          </SubMenu>
          <SubMenu label="Relatórios de Consultas" icon={<AssessmentIcon />}>
            <MenuItem
              component={<Link to="/home/relatorios/relatoriosCNPJ" />}
              icon={<SummarizeIcon />}
            >
              Relatórios de CNPJ
            </MenuItem>
          </SubMenu>
        </div>
      );
    }
  }

  function CadInnophos() {
    if (API_BASE !== 'innophos') {
      return (
        <Menu>
          <MenuItem
            icon={<MenuOutlinedIcon />}
            onClick={() => {
              collapseSidebar();
            }}
          >
            {' '}
          </MenuItem>

          <MenuItem
            icon={<HomeIcon />}
            {...props}
            component={<Link to="/home" />}
          >
            Home
          </MenuItem>
          <SubMenu label="Mensagens" icon={<EmailIcon />}>
            <MenuItem
              icon={<InboxIcon />}
              component={<Link to="/home/mensagens/federal" />}
            >
              Federal
            </MenuItem>
            <MenuItem
              icon={<InboxIcon />}
              component={<Link to="/home/mensagens/estadual" />}
            >
              Estadual
            </MenuItem>
            <MenuItem
              icon={<InboxIcon />}
              component={<Link to="/home/mensagens/municipal" />}
            >
              Municipal
            </MenuItem>
          </SubMenu>
          <SubMenu label="Cadastro" icon={<PersonIcon />}>
            <MenuItem
              icon={<ApartmentIcon />}
              component={<Link to="/home/cadastro/empresas" />}
            >
              Empresas
            </MenuItem>
            <MenuItem
              icon={<NotificationsIcon />}
              component={<Link to="/home/cadastro/notificacoes" />}
            >
              Notificações
            </MenuItem>
          </SubMenu>
          <SubMenu label="Parametrização" icon={<InventoryIcon />}>
            <MenuItem
              icon={<FingerprintIcon />}
              component={<Link to="/home/parametrizacao/certificados" />}
            >
              Certificados
            </MenuItem>
            <MenuItem
              icon={<AccountBalanceIcon />}
              component={<Link to="/home/parametrizacao/orgaos" />}
            >
              Órgãos
            </MenuItem>
            <MenuItem
              icon={<NotificationsIcon />}
              component={<Link to="/home/parametrizacao/notificacoes" />}
            >
              Notificações
            </MenuItem>
          </SubMenu>
          <LocalHost />
          <MenuItem>
            <IconButton onClick={logout} sx={{ color: '#2b2c5f' }}>
              <LogoutIcon />
            </IconButton>
            Sair
          </MenuItem>
        </Menu>
      );
    } else {
      return (
        <Menu>
          <MenuItem
            icon={<MenuOutlinedIcon />}
            onClick={() => {
              collapseSidebar();
            }}
          >
            {' '}
          </MenuItem>

          <MenuItem
            icon={<HomeIcon />}
            {...props}
            component={<Link to="/home" />}
          >
            Home
          </MenuItem>

          <SubMenu label="Cadastro" icon={<PersonIcon />}>
            <MenuItem
              icon={<ApartmentIcon />}
              component={<Link to="/home/cadastro/empresas" />}
            >
              Empresas
            </MenuItem>
          </SubMenu>

          <LocalHost />
          <MenuItem>
            <IconButton onClick={logout} sx={{ color: '#2b2c5f' }}>
              <LogoutIcon />
            </IconButton>
            Sair
          </MenuItem>
        </Menu>
      );
    }
  }

  return (
    <div>
      {matches ? (
        <div
          id="app"
          style={
            ({ height: '100vh' },
            { display: 'flex', flexDirection: 'row', color: '#2b2c5f' })
          }
        >
          <Sidebar
            breakPoint="xxl"
            transitionDuration={300}
            backgroundColor="#fff"
            rtl={false}
            defaultCollapsed={true}
            style={{
              height: '100%',
              position: 'fixed',
              left: '0%',
              top: '0%',
            }}
          >
            <CadInnophos />
            {/* <Menu>
              <MenuItem
                icon={<MenuOutlinedIcon />}
                onClick={() => {
                  collapseSidebar();
                }}
              >
                {' '}
              </MenuItem>

              <MenuItem
                icon={<HomeIcon />}
                {...props}
                component={<Link to="/home" />}
              >
                Home
              </MenuItem>
              <SubMenu label="Mensagens" icon={<EmailIcon />}>
                <MenuItem
                  icon={<InboxIcon />}
                  component={<Link to="/home/mensagens/federal" />}
                >
                  Federal
                </MenuItem>
                <MenuItem
                  icon={<InboxIcon />}
                  component={<Link to="/home/mensagens/estadual" />}
                >
                  Estadual
                </MenuItem>
                <MenuItem
                  icon={<InboxIcon />}
                  component={<Link to="/home/mensagens/municipal" />}
                >
                  Municipal
                </MenuItem>
              </SubMenu>
              <SubMenu label="Cadastro" icon={<PersonIcon />}>
                <MenuItem
                  icon={<ApartmentIcon />}
                  component={<Link to="/home/cadastro/empresas" />}
                >
                  Empresas
                </MenuItem>
                <MenuItem
                  icon={<NotificationsIcon />}
                  component={<Link to="/home/cadastro/notificacoes" />}
                >
                  Notificações
                </MenuItem>
              </SubMenu>
              <SubMenu label="Parametrização" icon={<InventoryIcon />}>
                <MenuItem
                  icon={<FingerprintIcon />}
                  component={<Link to="/home/parametrizacao/certificados" />}
                >
                  Certificados
                </MenuItem>
                <MenuItem
                  icon={<AccountBalanceIcon />}
                  component={<Link to="/home/parametrizacao/orgaos" />}
                >
                  Órgãos
                </MenuItem>
                <MenuItem
                  icon={<NotificationsIcon />}
                  component={<Link to="/home/parametrizacao/notificacoes" />}
                >
                  Notificações
                </MenuItem>

                <MenuItem
                  icon={<SearchIcon />}
                  component={<Link to="/home/parametrizacao/consultaBasica" />}
                >
                  Consulta Básica
                </MenuItem>
                <MenuItem
                  icon={<SavedSearchIcon />}
                  component={
                    <Link to="/home/parametrizacao/consultaAvancada" />
                  }
                >
                  Consulta Avançada
                </MenuItem>
              </SubMenu>
              <LocalHost />
              <MenuItem>
                <IconButton onClick={logout} sx={{ color: '#2b2c5f' }}>
                  <LogoutIcon />
                </IconButton>
                Sair
              </MenuItem>
            </Menu> */}
          </Sidebar>
        </div>
      ) : (
        <AppBarTop logout={logout} />
      )}
    </div>
  );
}

export default Teste;
