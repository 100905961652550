import React from 'react';
import NavBar from '../navBar';
import Head from '../Head';
import Header from '../Header';
import './Inicio.css';
import { Typography } from '@mui/material';
import { CircularProgress, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import DraftsIcon from '@mui/icons-material/Drafts';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import FolderIcon from '@mui/icons-material/Folder';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE, API_URL } from '../API/apiManager';

const Inicio = () => {
  const [ecac, setEcac] = useState([]);
  const [sefaz, setSefaz] = useState([]);
  const [prefeitura, setPrefeitura] = useState([]);
  const [redarf, setRedarf] = useState([]);
  const [intimacao, setIntimacao] = useState([]);
  const [importantes, setImportantes] = useState([]);
  const [eProcesso, setEprocesso] = useState([]);
  const [dctf, setDctf] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + '/dashboard', {
        headers: {
          Base: API_BASE,
        },
      })
      .then((response) => response.data)
      .then((result) => {
        setLoading(false);
        setEcac(result.ECAC);
        setSefaz(result.SEFAZ);
        setPrefeitura(result.PREFEITURA);
        setRedarf(result.REDARF);
        setIntimacao(result.INTIMACAO);
        setImportantes(result.IMPORTANTES);
        setEprocesso(result.EPROCESSO);
        setDctf(result.DCTF);
      })
      .catch((error) => error);
  }, []);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <div>
      <Head title="Sonar | Home" />
      <Header />
      <div className="conteudoCaixas">
        {/* aqui começa o card 1 */}
        {loading ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              top: 350,
              zIndex: 10000,
            }}
          >
            <CircularProgress style={{ color: '#fff' }} />
          </Box>
        ) : (
          <div>
            <section
              className="animeLeft"
              style={{
                marginTop: '2rem',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <Card sx={{ marginRight: '2rem' }} className="caixas">
                <CardContent>
                  <h4 className="caixasTitle">Mensagens Federais</h4>
                  <Typography
                    sx={{ mb: 1.5, mt: 5.5 }}
                    textAlign="center"
                    variant="body1"
                  >
                    <div
                      className="iconsCaixas"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <DraftsIcon sx={{ mr: 1 }} style={{ color: '#2b2c5f' }} />
                      <span className="spanCaixas">Lidas: {ecac.READ}</span>
                    </div>
                  </Typography>
                  <Typography textAlign="center" variant="body1">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <MarkunreadIcon
                        sx={{ mr: 1 }}
                        style={{ color: '#DF2E38' }}
                      />
                      <span className="spanCaixas">
                        Não Lidas: {ecac.UNREAD}
                      </span>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
              {/* aqui começa o card 2 */}
              <Card sx={{ marginRight: '2rem' }} className="caixas">
                <CardContent>
                  <h4 className="caixasTitle">Mensagens Estaduais</h4>
                  <Typography
                    sx={{ mb: 1.5, mt: 5.5 }}
                    textAlign="center"
                    variant="body1"
                  >
                    <div
                      className="iconsCaixas"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <DraftsIcon sx={{ mr: 1 }} style={{ color: '#2b2c5f' }} />
                      <span className="spanCaixas">Lidas: {sefaz.READ}</span>
                    </div>
                  </Typography>
                  <Typography textAlign="center" variant="body1">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <MarkunreadIcon
                        sx={{ mr: 1 }}
                        style={{ color: '#DF2E38' }}
                      />
                      <span className="spanCaixas">
                        Não Lidas: {sefaz.UNREAD}
                      </span>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
              {/* aqui começa o card 4 */}
              <Card sx={{ marginRight: '2rem' }} className="caixas">
                <CardContent>
                  <h4 className="caixasTitle">Mensagens Municipais</h4>
                  <Typography
                    sx={{ mb: 1.5, mt: 5.5 }}
                    textAlign="center"
                    variant="body1"
                  >
                    <div
                      className="iconsCaixas"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <DraftsIcon sx={{ mr: 1 }} style={{ color: '#2b2c5f' }} />
                      <span className="spanCaixas">
                        Lidas: {prefeitura.READ}
                      </span>
                    </div>
                  </Typography>
                  <Typography textAlign="center" variant="body1">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <MarkunreadIcon
                        sx={{ mr: 1 }}
                        style={{ color: '#DF2E38' }}
                      />
                      <span className="spanCaixas">
                        Não Lidas: {prefeitura.UNREAD}
                      </span>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
              {/* aqui começa o card 4 */}
              <Card sx={{ marginRight: '2rem' }} className="caixas">
                <CardContent>
                  <h4 className="caixasTitle">REDARF Net</h4>
                  <Typography
                    sx={{ mb: 1.5, mt: 5.5 }}
                    textAlign="center"
                    variant="body1"
                  >
                    <div
                      className="iconsCaixas"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <AssignmentTurnedInIcon
                        sx={{ mr: 1 }}
                        style={{ color: '#2b2c5f' }}
                      />
                      <span className="spanCaixas">
                        Regular: {redarf.REGULAR}
                      </span>
                    </div>
                  </Typography>
                  <Typography textAlign="center" variant="body1">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <AssignmentLateIcon
                        sx={{ mr: 1 }}
                        style={{ color: '#DF2E38' }}
                      />
                      <span className="spanCaixas">
                        Pendentes: {redarf.PENDING}
                      </span>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </section>
            {/* aqui começam os cards da segunda linha */}
            <div className="conteudoCaixas2">
              <section className="animeLeft" id="caixaLinha2">
                <Card sx={{ marginRight: '2rem' }} className="caixas">
                  <CardContent>
                    <h4 className="caixasTitle">Intimação</h4>
                    <Typography
                      sx={{ mb: 1.5, mt: 5.5 }}
                      textAlign="center"
                      variant="body1"
                    >
                      <div
                        className="iconsCaixas"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}
                      >
                        <AssignmentLateIcon
                          sx={{ mr: 1 }}
                          style={{ color: '#2b2c5f' }}
                        />
                        <span className="spanCaixas">
                          Pendentes: {intimacao.PENDING}
                        </span>
                      </div>
                    </Typography>
                    <Typography textAlign="center" variant="body1">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}
                      >
                        <FolderIcon
                          sx={{ mr: 1 }}
                          style={{ color: '#DF2E38' }}
                        />
                        <span className="spanCaixas">
                          Arquivadas: {intimacao.ARCHIVED}
                        </span>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
                {/* aqui começa o card 2 */}
                <Card sx={{ marginRight: '2rem' }} className="caixas">
                  <CardContent>
                    <h4 className="caixasTitle">Importantes</h4>
                    <Typography
                      sx={{ mb: 1.5, mt: 5.5 }}
                      textAlign="center"
                      variant="body1"
                    >
                      <div
                        className="iconsCaixas"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}
                      >
                        <DraftsIcon
                          sx={{ mr: 1 }}
                          style={{ color: '#2b2c5f' }}
                        />
                        <span className="spanCaixas">
                          Lidas: {importantes.READ}
                        </span>
                      </div>
                    </Typography>
                    <Typography textAlign="center" variant="body1">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}
                      >
                        <MarkunreadIcon
                          sx={{ mr: 1 }}
                          style={{ color: '#DF2E38' }}
                        />
                        <span className="spanCaixas">
                          Não Lidas: {importantes.UNREAD}
                        </span>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
                {/* aqui começa o card 3 */}

                <Card sx={{ marginRight: '2rem' }} className="caixas">
                  <CardContent>
                    <h4 className="caixasTitle">e-Processo</h4>
                    <Typography
                      sx={{ mb: 1.5, mt: 5.5 }}
                      textAlign="center"
                      variant="body1"
                    >
                      <div
                        className="iconsCaixas"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}
                      >
                        <AssignmentTurnedInIcon
                          sx={{ mr: 1 }}
                          style={{ color: '#2b2c5f' }}
                        />
                        <span className="spanCaixas">
                          Regular: {eProcesso.REGULAR}
                        </span>
                      </div>
                    </Typography>
                    <Typography textAlign="center" variant="body1">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}
                      >
                        <AssignmentLateIcon
                          sx={{ mr: 1 }}
                          style={{ color: '#DF2E38' }}
                        />
                        <span className="spanCaixas">
                          Pendentes: {eProcesso.PENDING}
                        </span>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
                {/* aqui começa o card 3 */}
                <Card
                  sx={{ marginRight: '2.1rem', maxWidth: 300 }}
                  className="caixas"
                >
                  <CardContent>
                    <h4 className="caixasTitle">DCTF</h4>
                    <Typography
                      sx={{ mb: 1.5, mt: 5.5 }}
                      textAlign="center"
                      variant="body1"
                    >
                      <div
                        className="iconsCaixas"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}
                      >
                        <CheckIcon
                          sx={{ mr: 1 }}
                          style={{ color: '#2b2c5f' }}
                        />
                        <span className="spanCaixas">
                          Entregues: {dctf.DELIVERED}
                        </span>
                      </div>
                    </Typography>
                    <Typography textAlign="center" variant="body1">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}
                      >
                        <PriorityHighIcon
                          sx={{ mr: 1 }}
                          style={{ color: '#DF2E38' }}
                        />
                        <span className="spanCaixas">
                          Não entregues: {dctf.UNDELIVERED}
                        </span>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </section>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Inicio;
