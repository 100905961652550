import React from 'react'
import ForgotPassword from '../ForgotPassword';
import Head from '../Head';
import Logo from '../LogoPollvo';

export default function ForgotPass() {
  return (
    <div style={{textAlign: "center"}}>
      <Head title="Sonar | Esqueci Minha Senha" />
      <Logo/>
      <p className='textoResetar'>Resetar Senha</p>
      <ForgotPassword/>
      </div>
  )
}
