import React, { useState, useEffect } from 'react';
import { TablePagination, Stack } from '@mui/material';
import { Table } from '@mui/material';
import { TableBody } from '@mui/material';
import './Orgaos.css';
import { TableCell } from '@mui/material';
import { TableContainer } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableRow } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import IconsAcoes from './IconsAcoes';
import axios from 'axios';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { API_BASE, API_URL } from './API/apiManager';

export default function Data() {
  const [dataTable, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + '/companies', {
        headers: {
          Base: API_BASE,
        },
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        setTableData(response.data);
      });
  }, []);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataTable.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div className="bgEmpresas">
        <Stack className="stackContainerGrid">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    size="small"
                    className="tableEmpresa"
                  >
                    ID
                  </TableCell>
                  <TableCell align="center">Razão Social</TableCell>
                  <TableCell align="center">CNPJ</TableCell>
                  <TableCell align="center">Situação Empresa</TableCell>
                  <TableCell align="center">Criação Usuário</TableCell>
                  <TableCell align="center">Data de Inclusão</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <Box className="ProgressBar">
                    <CircularProgress style={{ color: '#2b2c5f' }} />
                  </Box>
                ) : (
                  (rowsPerPage > 0
                    ? dataTable.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : dataTable
                  ).map((item, id) => (
                    <TableRow key={id}>
                      <TableCell align="center">{item.IDCOMPANY}</TableCell>
                      <TableCell align="center" style={{ width: 160 }}>
                        {item.RAZAOSOCIAL}
                      </TableCell>
                      <TableCell align="center">
                        {item.CNPJ.replace(/\D/g, '').replace(
                          /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
                          '$1.$2.$3/$4-$5',
                        )}
                      </TableCell>

                      <TableCell align="center">
                        {item.ATIVO ? (
                          <CheckIcon style={{ color: 'green' }} />
                        ) : (
                          <ClearIcon style={{ color: 'red' }} />
                        )}
                      </TableCell>
                      <TableCell align="center">{item.CREATEDBY}</TableCell>
                      <TableCell align="center">{item.CREATEDON}</TableCell>
                      <TableCell align="center">
                        <IconsAcoes empresa={item} />
                      </TableCell>
                    </TableRow>
                  ))
                )}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todas', value: -1 }]}
              colSpan={3}
              count={dataTable.length}
              rowsPerPage={rowsPerPage}
              component="div"
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Stack>
      </div>
    </>
  );
}
