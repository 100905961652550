import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import './IconsAcoes.css';
import warning from '../Components/imgs/warning.svg';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TabsEditEmpresasFiliais from './TabsEditEmpresasFiliais';
import axios from 'axios';
import { API_BASE, API_URL } from './API/apiManager';
import './EditEmpresas.css';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 310,
  maxHeight: 450,
  minHeight: 250,
  color: '#252525',
  bgcolor: 'background.paper',
  border: '1px solid #2b2c5f',
  boxShadow: 24,
  borderRadius: '0.75rem',
  p: 4,
};

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 900,
  minWidth: 310,
  height: 'auto',
  color: '#252525',
  bgcolor: 'background.paper',
  border: '1px solid #2b2c5f',
  boxShadow: 24,
  borderRadius: '0.75rem',
  p: 4,
};

export default function IconsAcoes({ empresa }) {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');

  const [openError, setOpenError] = React.useState(false);
  const [openEmpty, setOpenEmpty] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseShowModal = () => {
    setShowModal(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
    setOpenEmpty(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenWarning(false);
  };

  const handleDelete = async () => {
    try {
      await deleteSubsidiaries();
      await deleteCompany();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteSubsidiaries = async () => {
    try {
      const myHeaders = {
        Base: API_BASE,
        cnpjcompany: empresa.CNPJ,
      };
      const requestOptions = {
        headers: myHeaders,
        method: 'DELETE',
        redirect: 'follow',
        url: API_URL + '/subsidiaries/all',
      };
      const response = await axios(requestOptions);

      const result = response.data;
      if (result.statusCode === 200) {
        setOpenSuccess(true);
      } else {
        setOpenError(true);
      }
    } catch (err) {
      setOpenError(true);
    }
  };

  const deleteCompany = async () => {
    try {
      const myHeaders = {
        Base: API_BASE,
        cnpjcompany: empresa.CNPJ,
      };
      const requestOptions = {
        headers: myHeaders,
      };
      // console.log(empresa.CNPJ);
      const response = await axios.delete(
        API_URL + '/companie',
        requestOptions,
      );
      const result = response.data;
      if (result.statusCode === 200) {
        setOpenSuccess(true);
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      } else {
        setOpenError(true);
      }
    } catch (err) {
      setOpenError(true);
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <div>
      <IconButton onClick={handleShowModal}>
        <EditIcon />
      </IconButton>
      <Modal open={showModal} onClose={handleCloseShowModal}>
        <Box sx={styles}>
          <TabsEditEmpresasFiliais empresa={empresa} />
        </Box>
      </Modal>
      <IconButton onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Modal open={open}>
        <Box sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '1rem',
            }}
          >
            <img src={warning} />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h1"
            textAlign="center"
          >
            Cuidado!
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, mb: 2 }}
            textAlign="center"
          >
            Deseja excluir esta empresa e{' '}
            <b style={{ color: 'red' }}>TODAS AS SUAS FILIAIS</b>?
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={handleDelete}
              //onClick={deleteCompany}
              variant="contained"
              endIcon={<DeleteIcon />}
              sx={{ mr: 2 }}
              style={{ backgroundColor: 'green' }}
            >
              Sim, excluir agora!
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              style={{ backgroundColor: 'red' }}
            >
              Cancelar!
            </Button>
          </div>
          {message ? (
            <p
              style={{
                color: 'green',
                textAlign: 'center',
                marginTop: '10px',
              }}
            >
              {message}
            </p>
          ) : (
            <p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
              {message}
            </p>
          )}
        </Box>
      </Modal>
    </div>
  );
}
