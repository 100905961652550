import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import UnstyledTabsIntroduction from './TabCadastros';
import './Orgaos.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 1000,
  minWidth: 250,
  maxHeight: 580,
  minHeight: 280,
  bgcolor: 'background.paper',
  color: '#252525',
  border: '1px solid #2b2c5f',
  boxShadow: 24,
  borderRadius: '0.75rem',
  p: 4,
};

const styleButton = {
  color: '#fff',
  backgroundColor: '#2b2c5f',
  ':hover': {
    backgroundColor: '#3b4e82 ',
    color: '#fff',
    borderColor: '#2b2c5f',
  },
};
export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="divEmpresas">
      <Button
        onClick={handleOpen}
        variant="contained"
        sx={styleButton}
        size="medium"
        endIcon={<AddIcon fontSize="small" />}
      >
        Criar nova Empresa
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <UnstyledTabsIntroduction />
        </Box>
      </Modal>
    </div>
  );
}
