import React from 'react';
import './Header.css';
import LogoSonar from './imgs/sonarLogoBranco.png';

export default function Header() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }} className="headerSite"> 
      <header className="headerInicio">
        <p
        className="paragrafoSonarHeader">
          Sonar
        </p>
        <img src={LogoSonar} className="sonarLogoBranco" />
      </header>
    </div>
  );
}
