import React from 'react';
import { Tabs, Tab, Box, useMediaQuery } from '@mui/material';
import ColumnChart from '../ColumnChart/ColumnChart';
import ColumnChartAvancada from '../ColumnChart/ColumnChartAvancada';
import './styles.css';

const TabsChart = ({ dateStart, dateEnd }) => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        className="tab-panel"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className="tab-panel-content">
            <div>{children}</div>
          </div>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="#fff"
        sx={{
          marginBottom: '20px',
          backgroundColor: 'white',
          color: '#2b2c5f',
          padding: 0,
          textTransform: 'none',
          borderRadius: '0.75rem',
        }}
        TabIndicatorProps={{
          style: { background: '#2b2c5f' },
        }}
        variant={isMobile ? 'scrollable' : 'standard'}
      >
        <Tab label="Consultas Básicas" {...a11yProps(0)} />
        <Tab label="Consultas Avançadas" {...a11yProps(0)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ColumnChart dateStart={dateStart} dateEnd={dateEnd} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ColumnChartAvancada dateStart={dateStart} dateEnd={dateEnd} />
      </TabPanel>
    </Box>
  );
};

export default TabsChart;
