import { Divider, ThemeProvider } from '@mui/material';
import React, { useState } from 'react';
import './CadastroEmailNotificacao.css';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme } from '@mui/material';
import { green, yellow, red } from '@mui/material/colors';
import ReactInputMask from 'react-input-mask';
import axios from 'axios';
import { API_BASE, API_URL } from './API/apiManager';

const theme = createTheme({
  palette: {
    primary: {
      main: green[500],
      contrastText: '#fff',
    },
    secondary: {
      main: yellow[600],
      contrastText: '#000',
    },
    warning: {
      main: red[500],
    },
    padrao: {
      main: '#0288d1',
    },
    texto: {
      main: '#252525',
    },
  },
  typography: {
    fontFamily: 'Calibri',
    fontSize: 14,
  },
});

const EditNotificacoes = ({ notificacao }) => {
  const [notificacaoEdit, setNotificacaoEdit] = useState(notificacao);
  const [message, setMessage] = useState('');
  const [value, setValue] = useState(notificacao);

  //onchange dos níveis de importância das msgs
  const handleChangeSMS = (event) => {
    setNotificacaoEdit({
      ...notificacaoEdit,
      SENDSMS: event.target.checked,
    });
  };
  const handleChangeMail = (event) => {
    setNotificacaoEdit({
      ...notificacaoEdit,
      SENDMAIL: event.target.checked,
    });
  };

  const handleChangeLow = (event) => {
    setNotificacaoEdit({
      ...notificacaoEdit,
      LOW: event.target.checked,
    });
  };
  const handleChangeMedium = (event) => {
    setNotificacaoEdit({
      ...notificacaoEdit,
      MEDIUM: event.target.checked,
    });
  };
  const handleChangeHigh = (event) => {
    setNotificacaoEdit({
      ...notificacaoEdit,
      HIGH: event.target.checked,
    });
  };
  const handleChangeEmail = (event) => {
    setNotificacaoEdit({
      ...notificacaoEdit,
      EMAIL: event.target.value,
    });
  };
  const handlePhoneNumberChange = (event) => {
    setNotificacaoEdit({ ...notificacaoEdit, PHONENUMBER: event.target.value });
  };

  //função para put com axios
  const handlePutNotificacoes = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        Base: API_BASE,
        idnotification: notificacaoEdit.IDNOTIFICATION,
        sendsms: notificacaoEdit.SENDSMS,
        sendmail: notificacaoEdit.SENDMAIL,
        email: notificacaoEdit.EMAIL,
        phonenumber: notificacaoEdit.PHONENUMBER,
        low: notificacaoEdit.LOW,
        medium: notificacaoEdit.MEDIUM,
        high: notificacaoEdit.HIGH,
      };
      const res = await axios.put(
        API_URL + '/notifications',
        {},
        {
          headers: headers,
        },
      );
      // console.log(res);
      if (res.data.statusCode === 200) {
        setMessage('Notificação alterada com sucesso!');
        window.location.reload(true);
      } else {
        setMessage(res.data.body.Mensagem);
      }
    } catch (err) {
      setMessage('Erro! Verifique os dados!');
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Divider>Cadastro</Divider>
        <p style={{ textAlign: 'center', marginTop: '1rem' }}>
          Cadastre seu número de celular e/ou e-mail para recebimento de
          notificações
        </p>
        <form onSubmit={handlePutNotificacoes}>
          <div className="containerPhoneInput">
            <ReactInputMask
              className="inputNotificao"
              id="inputEmailNotificacao"
              value={notificacaoEdit.PHONENUMBER}
              placeholder="(99) 99999-9999"
              mask="+55 (99) 99999-9999"
              onChange={handlePhoneNumberChange}
            />
            {/* <PhoneInput
              className="inputNotificao"
              international
              name="PHONENUMBER"
              defaultCountry="BR"
              placeholder="(99) 99999-9999"
              value={notificacaoEdit.PHONENUMBER}
              onChange={handlePhoneNumberChange}
              style={{ width: '300px', marginTop: '1rem' }}
            /> */}
            <input
              type="email"
              name="EMAIL"
              id="inputEmailNotificacao"
              placeholder="Digite seu email"
              value={notificacaoEdit.EMAIL}
              onChange={handleChangeEmail}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  value={notificacaoEdit.SENDSMS}
                  name="sms"
                  checked={notificacaoEdit.SENDSMS}
                  onChange={handleChangeSMS}
                  color="padrao"
                />
              }
              label="Receber notificação por SMS"
            />
            <FormControlLabel
              control={
                <Switch
                  value={notificacaoEdit.SENDMAIL}
                  name="email"
                  checked={notificacaoEdit.SENDMAIL}
                  onChange={handleChangeMail}
                  color="padrao"
                />
              }
              label="Receber notificação por e-mail"
            />
          </div>
          <Divider sx={{ mb: '2rem', mt: '1rem' }}>Notificações</Divider>
          <div className="containerSwitchNot">
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend" sx={{ mb: '2rem' }} color="texto">
                Qual grau de importância de notificação deseja receber?
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      value={notificacaoEdit.LOW}
                      checked={notificacaoEdit.LOW}
                      onChange={handleChangeLow}
                      name="baixo"
                      theme={theme}
                      color="primary"
                    />
                  }
                  label="Baixo"
                />
                <FormControlLabel
                  control={
                    <Switch
                      value={notificacaoEdit.MEDIUM}
                      checked={notificacaoEdit.MEDIUM}
                      onChange={handleChangeMedium}
                      name="medio"
                      theme={theme}
                      color="secondary"
                    />
                  }
                  label="Médio"
                />
                <FormControlLabel
                  control={
                    <Switch
                      value={notificacaoEdit.HIGH}
                      checked={notificacaoEdit.HIGH}
                      onChange={handleChangeHigh}
                      name="alto"
                      theme={theme}
                      color="warning"
                    />
                  }
                  label="Alto"
                />
              </FormGroup>
            </FormControl>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '3rem',
            }}
          >
            <Button variant="contained" id="Button" type="submit">
              Salvar <SaveAltIcon />
            </Button>
          </div>

          <div
            className="message"
            style={{
              position: 'relative',
              top: '10px',
              fontSize: '1rem',
            }}
          >
            {message ? (
              <p style={{ color: 'green' }}>{message}</p>
            ) : (
              <p style={{ color: 'red' }}>{message}</p>
            )}
          </div>
        </form>
      </ThemeProvider>
    </>
  );
};

export default EditNotificacoes;
