import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import { NestedMenuItem } from 'mui-nested-menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { API_BASE } from './API/apiManager';

const AppBarTop = ({ logout }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function LocalHost() {
    if (API_BASE === 'localhost') {
      return (
        <div>
          <NestedMenuItem label="Consultas" parentMenuOpen={Boolean(anchorEl)}>
            {' '}
            <MenuItem>
              {' '}
              <Link
                to="/home/consultas/consultaBasica"
                style={{ textDecoration: 'none', color: '#252525' }}
              >
                Consulta Básica
              </Link>
            </MenuItem>
            <MenuItem>
              {' '}
              <Link
                to="/home/consultas/consultaAvancada"
                style={{ textDecoration: 'none', color: '#252525' }}
              >
                Consulta Avançada
              </Link>
            </MenuItem>
          </NestedMenuItem>
          <MenuItem>
            <Link
              to="/home/relatorios"
              style={{ textDecoration: 'none', color: '#252525' }}
            >
              Relatórios de Consultas
            </Link>
          </MenuItem>
        </div>
      );
    }
  }

  function CadInnophos() {
    if (API_BASE !== 'innophos') {
      return (
        <AppBar
          position="static"
          sx={{
            width: '400px',
            backgroundColor: '#fff',
            color: '#2b2c5f',
            marginTop: '-8px',
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <Link
                  to="/home"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Home
                </Link>
              </MenuItem>

              <NestedMenuItem
                label="Mensagens"
                parentMenuOpen={Boolean(anchorEl)}
              >
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/home/mensagens/federal"
                    style={{ textDecoration: 'none', color: '#252525' }}
                  >
                    Federal
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/home/mensagens/estadual"
                    style={{ textDecoration: 'none', color: '#252525' }}
                  >
                    Estadual
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/home/mensagens/municipal"
                    style={{ textDecoration: 'none', color: '#252525' }}
                  >
                    Municipal
                  </Link>
                </MenuItem>
              </NestedMenuItem>

              <NestedMenuItem
                label="Cadastro"
                parentMenuOpen={Boolean(anchorEl)}
              >
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/home/cadastro/empresas"
                    style={{ textDecoration: 'none', color: '#252525' }}
                  >
                    Empresas
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/home/cadastro/notificacoes"
                    style={{ textDecoration: 'none', color: '#252525' }}
                  >
                    Notificações
                  </Link>
                </MenuItem>
              </NestedMenuItem>

              <NestedMenuItem
                label="Parametrização"
                parentMenuOpen={Boolean(anchorEl)}
              >
                <MenuItem onClick={handleClose}>
                  <Link
                    to="/home/parametrizacao/certificados"
                    style={{ textDecoration: 'none', color: '#252525' }}
                  >
                    Certificados
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {' '}
                  <Link
                    to="/home/parametrizacao/orgaos"
                    style={{ textDecoration: 'none', color: '#252525' }}
                  >
                    Órgãos
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    to="/home/parametrizacao/notificacoes"
                    style={{ textDecoration: 'none', color: '#252525' }}
                  >
                    Notificações
                  </Link>
                </MenuItem>

                <MenuItem>
                  {' '}
                  <Link
                    to="/home/parametrizacao/consultaBasica"
                    style={{ textDecoration: 'none', color: '#252525' }}
                  >
                    Consulta Básica
                  </Link>
                </MenuItem>
                <MenuItem>
                  {' '}
                  <Link
                    to="/home/parametrizacao/consultaAvancada"
                    style={{ textDecoration: 'none', color: '#252525' }}
                  >
                    Consulta Avançada
                  </Link>
                </MenuItem>
              </NestedMenuItem>
              <LocalHost />
            </Menu>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, fontFamily: 'Nova Mono' }}
            >
              Pollvo
            </Typography>

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={logout}
                color="inherit"
              >
                <LogoutIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      );
    } else {
      <AppBar
        position="static"
        sx={{
          width: '400px',
          backgroundColor: '#fff',
          color: '#2b2c5f',
          marginTop: '-8px',
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Link
                to="/home"
                style={{ textDecoration: 'none', color: '#252525' }}
              >
                Home
              </Link>
            </MenuItem>

            {/* <NestedMenuItem
              label="Mensagens"
              parentMenuOpen={Boolean(anchorEl)}
            >
              <MenuItem onClick={handleClose}>
                <Link
                  to="/home/mensagens/federal"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Federal
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  to="/home/mensagens/estadual"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Estadual
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  to="/home/mensagens/municipal"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Municipal
                </Link>
              </MenuItem>
            </NestedMenuItem> */}

            <NestedMenuItem label="Cadastro" parentMenuOpen={Boolean(anchorEl)}>
              <MenuItem onClick={handleClose}>
                <Link
                  to="/home/cadastro/empresas"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Empresas
                </Link>
              </MenuItem>
              {/* <MenuItem onClick={handleClose}>
                <Link
                  to="/home/cadastro/notificacoes"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Notificações
                </Link>
              </MenuItem> */}
            </NestedMenuItem>

            <NestedMenuItem
              label="Parametrização"
              parentMenuOpen={Boolean(anchorEl)}
            >
              {/* <MenuItem onClick={handleClose}>
                <Link
                  to="/home/parametrizacao/certificados"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Certificados
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <Link
                  to="/home/parametrizacao/orgaos"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Órgãos
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="/home/parametrizacao/notificacoes"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Notificações
                </Link>
              </MenuItem> */}

              <MenuItem>
                {' '}
                <Link
                  to="/home/parametrizacao/consultaBasica"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Consulta Básica
                </Link>
              </MenuItem>
              <MenuItem>
                {' '}
                <Link
                  to="/home/parametrizacao/consultaAvancada"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Consulta Avançada
                </Link>
              </MenuItem>
            </NestedMenuItem>
            <LocalHost />
          </Menu>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontFamily: 'Nova Mono' }}
          >
            Pollvo
          </Typography>

          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={logout}
              color="inherit"
            >
              <LogoutIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>;
    }
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <CadInnophos />
      {/* <AppBar
        position="static"
        sx={{
          width: '400px',
          backgroundColor: '#fff',
          color: '#2b2c5f',
          marginTop: '-8px',
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
           <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Link
                to="/home"
                style={{ textDecoration: 'none', color: '#252525' }}
              >
                Home
              </Link>
            </MenuItem>

            <NestedMenuItem
              label="Mensagens"
              parentMenuOpen={Boolean(anchorEl)}
            >
              <MenuItem onClick={handleClose}>
                <Link
                  to="/home/mensagens/federal"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Federal
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  to="/home/mensagens/estadual"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Estadual
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  to="/home/mensagens/municipal"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Municipal
                </Link>
              </MenuItem>
            </NestedMenuItem>

            <NestedMenuItem label="Cadastro" parentMenuOpen={Boolean(anchorEl)}>
              <MenuItem onClick={handleClose}>
                <Link
                  to="/home/cadastro/empresas"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Empresas
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  to="/home/cadastro/notificacoes"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Notificações
                </Link>
              </MenuItem>
            </NestedMenuItem>

            <NestedMenuItem
              label="Parametrização"
              parentMenuOpen={Boolean(anchorEl)}
            >
              <MenuItem onClick={handleClose}>
                <Link
                  to="/home/parametrizacao/certificados"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Certificados
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                {' '}
                <Link
                  to="/home/parametrizacao/orgaos"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Órgãos
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="/home/parametrizacao/notificacoes"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Notificações
                </Link>
              </MenuItem>

             <MenuItem>
                {' '}
                <Link
                  to="/home/parametrizacao/consultaBasica"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Consulta Básica
                </Link>
              </MenuItem>
              <MenuItem>
                {' '}
                <Link
                  to="/home/parametrizacao/consultaAvancada"
                  style={{ textDecoration: 'none', color: '#252525' }}
                >
                  Consulta Avançada
                </Link>
              </MenuItem> 
            </NestedMenuItem> 
            <LocalHost />
          </Menu>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontFamily: 'Nova Mono' }}
          >
            Pollvo
          </Typography>

          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={logout}
              color="inherit"
            >
              <LogoutIcon />
            </IconButton>
          </div> 
        </Toolbar>
      </AppBar> */}
    </Box>
  );
};

export default AppBarTop;
