import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Inicio from './Components/Pages/Inicio';
import Federal from './MsgFederal';
import Estadual from './MsgEstadual';
import Municipal from './MsgMunicipal';
import Empresas from './EmpresasCadastro';
import Certificados from './Certificados';
import Orgãos from './Components/Orgãos';
import ConsultaBasica from './Components/Consulta/Basica';
import ConsultAvancada from './Components/Consulta/Avancada';
import Notificações from './Notificações';
import ParametrizaçãoNotificação from './Components/ParametrizacaoNotificacoes';
import RelatoriosCNPJ from './Components/Relatorios/RelatoriosCNPJ';

export default function HomeRoutes() {
  const Private = ({ Item }) => {
    return sessionStorage.getItem('token') ? (
      <Item />
    ) : (
      window.location.assign('/login')
    );
  };

  return (
    <Routes>
      <Route exact path="/" element={<Private Item={Inicio} />} />
      <Route
        exact
        path="mensagens/federal"
        element={<Private Item={Federal} />}
      />
      <Route
        exact
        path="mensagens/estadual"
        element={<Private Item={Estadual} />}
      />
      <Route
        exact
        path="mensagens/municipal"
        element={<Private Item={Municipal} />}
      />
      <Route
        exact
        path="cadastro/empresas"
        element={<Private Item={Empresas} />}
      />
      <Route
        exact
        path="cadastro/notificacoes"
        element={<Private Item={Notificações} />}
      />
      <Route
        exact
        path="parametrizacao/certificados"
        element={<Private Item={Certificados} />}
      />
      <Route
        exact
        path="parametrizacao/orgaos"
        element={<Private Item={Orgãos} />}
      />
      <Route
        exact
        path="parametrizacao/notificacoes"
        element={<Private Item={ParametrizaçãoNotificação} />}
      />
      <Route
        exact
        path="consultas/consultaBasica"
        element={<Private Item={ConsultaBasica} />}
      />
      <Route
        exact
        path="consultas/consultaAvancada"
        element={<Private Item={ConsultAvancada} />}
      />
      <Route
        exact
        path="relatorios/relatoriosCNPJ"
        element={<Private Item={RelatoriosCNPJ} />}
      />
      {/* <Route path="/" element={<Inicio />} />
      <Route path="mensagens/federal" element={<Federal />} />
      <Route path="mensagens/estadual" element={<Estadual />} />
      <Route path="mensagens/municipal" element={<Municipal />} />
      <Route path="cadastro/empresas" element={<Empresas />} />
      <Route path="cadastro/notificacoes" element={<Notificações />} />
      <Route path="parametrizacao/certificados" element={<Certificados />} />
      <Route path="parametrizacao/orgaos" element={<Orgãos />} />
      <Route path="parametrizacao/notificacoes" element={<ParametrizaçãoNotificação />}
      /> */}
    </Routes>
  );
}
