import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Components/Login';
import HomeRoutes from './HomeRoutes';
import './App.css';
import { Account } from './Components/Account';
import Status from './Components/Status';
import ForgotPass from './Components/Pages/ForgotPass';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';

const tema = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      fontFamily: 'Calibri',
    },
  },
  ptBR,
);

const isMobile = window.innerWidth > 768;

function MyRoutes() {
  const Private = ({ Item }) => {
    return sessionStorage.getItem('token') ? (
      <Item />
    ) : (
      window.location.assign('/login')
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resetarSenha" element={<ForgotPass />} />
        <Route
          exact
          path="/home/*"
          element={
            sessionStorage.getItem('token') ? (
              <Status>
                {' '}
                <HomeRoutes />{' '}
              </Status>
            ) : (
              <Login />
            )
          }
        />
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={tema}>
        <Account>
          <MyRoutes />
        </Account>
      </ThemeProvider>
    </div>
  );
}

export default App;
