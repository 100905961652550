import React from 'react';
import BackgroundBranco from './imgs/BackgroundBranco6.png';

const Background = () => {
  return (
    <>
      <img src={BackgroundBranco} width="1200" className="background" />
    </>
  );
};

export default Background;
