import { React, useEffect, useState } from 'react';
import './CaixaMensagens.css';
import MailIcon from '@mui/icons-material/Mail';
import DraftsIcon from '@mui/icons-material/Drafts';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Selects from './Selects';
import axios from 'axios';
import { CircularProgress, Stack } from '@mui/material';
import LeituraMensagemECAC from './LeituraMensagemECAC';
import { Box } from '@mui/system';
import { API_BASE, API_URL } from './API/apiManager';
import qs from 'qs';
import { createBrowserHistory } from 'history';
import { useLocation, useSearchParams } from 'react-router-dom';

function Tabs() {
  const [data, setData] = useState([]);
  const [mensagem, setMensagem] = useState([data.length - 1]);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState('');

  const history = createBrowserHistory({ forceRefresh: true });

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      let myHeaders = new Headers();
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Base', API_BASE);
      let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };
      await fetch(API_URL + '/mailbox/federal', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setLoading(false);
          setData(result);
        })
        .catch((error) => error);
    };
    fetchData();
  }, []);

  const fetchStatus = async (idmail) => {
    const headers = {
      Base: API_BASE,
      idmail: idmail,
    };
    const res = await axios.get(API_URL + '/mailbox/mailfup', {
      headers: headers,
    });
    const response = res.data;
    setStatus(response);
  };
  let [searchParams, setSearchParams] = useSearchParams();
  const [isActive, setIsActive] = useState(false);

  function handleReadMessage(index, event) {
    console.log(index);
    setMensagem(data[index]);
    fetchStatus(data[index]?.IDMAIL);
    setIsActive((event) => !event);
    setIndex(index);
  }

  const location = useLocation();

  useEffect(() => {
    const filterParams = history.location.search.substring(1);

    const filtersFromParams = qs.parse(filterParams);
    console.log(filtersFromParams);

    if (filtersFromParams.index) {
      setIndex(Number(filtersFromParams.index));
    }
  }, []);

  useEffect(() => {
    history.push(location.pathname + `?index=${index}`);
  }, [index]);

  return (
    <Stack>
      {loading ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            top: 350,
            zIndex: 10000,
          }}
        >
          <CircularProgress style={{ color: '#fff' }} />
        </Box>
      ) : (
        <div>
          {status.length > 0 &&
            status.map((status, index) => {
              return (
                <div key={index}>
                  <div className="statusMensagemProcessamento">
                    {status.IDFUP === 0 ? (
                      <p
                        className="pStatus"
                        style={{
                          color: 'red',
                        }}
                      >
                        Não Atribuído
                      </p>
                    ) : status.IDFUP === 1 ? (
                      <p
                        className="pStatus"
                        style={{
                          color: 'orange',
                        }}
                      >
                        Processando
                      </p>
                    ) : status.IDFUP === 2 ? (
                      <p
                        className="pStatus"
                        style={{
                          color: 'green',
                        }}
                      >
                        Finalizado
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            })}
          <div className="containerMensagens federal">
            <div className="divScroll">
              <nav className="TabNav">
                {' '}
                <ul className="ul">
                  {data.length &&
                    data
                      .map((item, index) => (
                        <li
                          key={index}
                          onClick={() => handleReadMessage(index)}
                          id="liArray"
                        >
                          <span className="remetente">{item.MAILSUBJECT}</span>
                          <p className="dataRecebimento">{item.RECEIVEDDATE}</p>

                          <p className="razaoSocialMensagens">
                            {item.RAZAOSOCIAL}
                          </p>
                          <span className="assunto">{item.MAILFROM}</span>
                          <p className="statusMensagem">
                            {<PriorityHighIcon style={{ color: 'red' }} />}
                            {item.READCHECK ? <DraftsIcon /> : <MailIcon />}
                          </p>
                          <hr className="hrEmails" />
                        </li>
                      ))
                      .reverse()}
                </ul>
              </nav>
            </div>
            <div className="contentMensagens">
              <strong>
                <p className="conteudoAssunto">
                  {mensagem.MAILSUBJECT === undefined
                    ? ''
                    : `Assunto: ${mensagem.MAILSUBJECT}`}
                </p>
              </strong>
              <p className="conteudoRazaoSocial">
                {mensagem.RAZAOSOCIAL === undefined
                  ? ''
                  : `Empresa: ${mensagem.RAZAOSOCIAL}`}
              </p>
              <p className="conteudoData">
                {mensagem.RECEIVEDDATE === undefined
                  ? ''
                  : `Data de Recebimento: ${mensagem.RECEIVEDDATE}`}
              </p>
              {status.length > 0 &&
                status.map((status, i) => {
                  return (
                    <div key={i}>
                      <p
                        className="userMensagem"
                        style={{ color: 'green', fontSize: 'medium' }}
                      >
                        Usuário: {status.CREATEDBY}
                      </p>
                      <p
                        className="userDataMensagem"
                        style={{ color: 'green', fontSize: 'medium' }}
                      >
                        Data Processamento: {status.CREATEDON}
                      </p>
                    </div>
                  );
                })}
              {/* {mensagem.READCHECK === undefined ? (
                ''
              ) : mensagem.READCHECK ? (
                <div
                  className="textoMensagem"
                  dangerouslySetInnerHTML={{ __html: mensagem.CONTENT }}
                  style={{ textAlign: 'center', marginTop: '0.75rem' }}
                />
              ) : (
                <LeituraMensagemECAC mensagem={mensagem} />
              )} */}

              <div
                className="textoMensagem"
                dangerouslySetInnerHTML={{ __html: mensagem.CONTENT }}
                style={{ textAlign: 'center', marginTop: '0.75rem' }}
              />

              <div className="divSelections">
                {mensagem.READCHECK === false ? (
                  ''
                ) : mensagem.CONTENT === undefined ? (
                  ''
                ) : (
                  <Selects status={status} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Stack>
  );
}

export default Tabs;
