import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';

const TableConsultas = ({ consultas }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, consultas.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <TableContainer style={{ overflow: 'auto' }}>
      <Table sx={{ width: isMobile ? '100%' : 800 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Data da Consulta</TableCell>
            <TableCell align="center">Tipo da Consulta</TableCell>
            <TableCell align="center">Usuário</TableCell>
            <TableCell align="center">CNPJ Consultado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? consultas.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              )
            : consultas
          ).map((row, i) => (
            <TableRow hover key={i}>
              <TableCell align="center">{row.CREATEDON}</TableCell>
              <TableCell align="center">{row.TYPE}</TableCell>
              <TableCell align="center">{row.USER}</TableCell>
              <TableCell align="center">
                {row.CNPJ === null
                  ? row.CNPJ
                  : row.CNPJ.replace(
                      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      '$1.$2.$3/$4-$5',
                    )}
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'Todas', value: -1 }]}
        colSpan={3}
        count={consultas.length}
        rowsPerPage={rowsPerPage}
        component="div"
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'Linhas Por Página' },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default TableConsultas;
