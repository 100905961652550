import React from 'react'
import LogoPollvo from './imgs/LogoBranca.svg';


const Logo = () => {
  const onClickUrl = (url) => {
    return () => openInNewTab(url)
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, 'nooopener, noreferrer');
    if(newWindow) newWindow.opener = null;
  }

  return (
    <>
      <header>
        <img src={LogoPollvo}
        alt="Logo Pollvo"
        className='LogoPollvo'
        onClick={onClickUrl('https://www.pollvo.com')}
        />
      </header>
    </>
  )
}

export default Logo
