import React from 'react';
import CadastroEmpresas from './Components/CadastroEmpresas';
import Head from './Components/Head';
import Header from './Components/Header';
import NavBar from './Components/navBar';
import BasicModal from './Components/ModalCadastro';

function EmpresasCadastro() {
  return (
    <div>
      <Head title="Sonar | Empresas" />
      <Header />
      <BasicModal/>
      <CadastroEmpresas/>
    </div>
  );
}

export default EmpresasCadastro;
