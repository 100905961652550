import React, { useEffect, useState } from 'react';
import {
  Select,
  Button,
  MenuItem,
  useMediaQuery,
  FormControl,
  Divider,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Box,
  IconButton,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Header from '../Header';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { API_BASE, API_URL } from '../API/apiManager';
import './basica.css';
import './Avancada.css';
import GaugeHistory from './Dialog/GaugeHistory';
import DialogAvançada from './Dialog/DialogAvançada';

const ConsultAvancada = () => {
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState('');
  const [filiais, setFiliais] = useState([]);
  const [filial, setFilial] = useState('');
  const [CNPJConsulta, setCNPJConsulta] = useState('');
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    axios
      .get(API_URL + '/companies', {
        headers: {
          Base: API_BASE,
        },
      })
      .then((response) => {
        setCompanies(response.data);
      });
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setCompany(typeof value === 'string' ? value.split(',') : value);
    const cnpj = value.substring(0, 14);
    getData(cnpj);
  };

  const getData = (cnpj) => {
    axios
      .get(API_URL + '/subsidiaries/bycnpj', {
        headers: {
          Base: API_BASE,
          CNPJCompany: cnpj,
        },
      })
      .then((res) => {
        let result = res.data;
        setFiliais(result);
      });
  };

  const handleChangeFilial = (event) => {
    setFilial(event.target.value);
    const cnpj = event.target.value.substring(0, 14);
    setCNPJConsulta(cnpj);
    getHistory(cnpj);
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const StyledSelect = styled(Select)({
    color: '#252525',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#2b2c5f',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2b2c5f',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2b2c5f',
    },
    marginTop: '10px',
    marginBottom: '10px',
    width: isMobile ? '300px' : '600px',
  });

  const getHistory = (cnpj) => {
    setLoading(true);
    axios
      .get(API_URL + '/queries/cnpj/history', {
        headers: {
          Base: API_BASE,
          CNPJ: cnpj,
          QueryType: '2',
        },
      })
      .then((response) => {
        setHistory(response.data);
        setLoading(false);
      });
  };

  function numberToReal(numero) {
    var numero = numero.toFixed(2).split('.');

    numero[0] = 'R$ ' + numero[0].split(/(?=(?:...)*$)/).join('.');

    return numero.join(',');
  }

  function Protestos(histories) {
    if (histories.CONTENT.protestos.cartorios) {
      return (
        <div>
          <p>
            CPF / CNPJ:{' '}
            {histories.CONTENT.protestos.cartorios.RJ[0].protestos[0].cpf_cnpj
              .replace(/\D/g, '')
              .replace(
                /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
                '$1.$2.$3/$4-$5',
              )}
          </p>
          <p>
            Data Protesto:{' '}
            {
              histories.CONTENT.protestos.cartorios.RJ[0].protestos[0]
                .data_protesto_string
            }
          </p>
          <p>
            Data Protesto:{' '}
            {
              histories.CONTENT.protestos.cartorios.RJ[0].protestos[0]
                .data_protesto_string
            }
          </p>
          <p>
            Data e Hora da Consulta:{' '}
            {histories.CONTENT.protestos.consulta_datahora}
          </p>
          <p>
            R${' '}
            {
              histories.CONTENT.protestos.cartorios.RJ[0].protestos[0]
                .valor_string
            }
          </p>
          <p>
            Verificar Protestos:
            <IconButton>
              <a
                href={histories.CONTENT.protestos.site_receipt}
                target="_blank"
                rel="noreferrer"
              >
                <CloudDownloadIcon sx={{ color: '#2b2c5f' }} />
              </a>
            </IconButton>
          </p>
          <p>
            Tem anuência?{' '}
            <strong>
              {
                histories.CONTENT.protestos.cartorios.RJ[0].protestos[0]
                  .tem_anuencia
              }
            </strong>
          </p>
          <p>
            Quantidade de Protestos:{' '}
            {histories.CONTENT.protestos.quantidade_titulos}
          </p>
        </div>
      );
    } else if (histories.CONTENT.protestos.bankrupts.bankruptsResponse) {
      return (
        <div>
          Falências
          <p>
            Data da falência:{' '}
            <strong>
              {
                histories.CONTENT.protestos.bankrupts.bankruptsResponse
                  .eventDate
              }
            </strong>
          </p>
          <p>
            Tipo de falência:{' '}
            <strong>
              {
                histories.CONTENT.protestos.bankrupts.bankruptsResponse
                  .eventType
              }
            </strong>
          </p>
          <p>
            Origem:{' '}
            <strong>
              {histories.CONTENT.protestos.bankrupts.bankruptsResponse.origin}
            </strong>
          </p>
          <p>
            Cidade de origem da falência:{' '}
            <strong>
              {histories.CONTENT.protestos.bankrupts.bankruptsResponse.city}
            </strong>
          </p>
          <p>
            Estado de origem da falência:{' '}
            <strong>
              {histories.CONTENT.protestos.bankrupts.bankruptsResponse.state}
            </strong>
          </p>
          <p>
            Data da negativação mais recente:{' '}
            <strong>
              {
                histories.CONTENT.protestos.bankrupts.bankruptsResponse.summary
                  .firstOcurrence
              }
            </strong>
          </p>
          <p>
            Data da negativação mais antiga:{' '}
            <strong>
              {
                histories.CONTENT.protestos.bankrupts.bankruptsResponse.summary
                  .lastOcurrence
              }
            </strong>
          </p>
          <p>
            Quantidade total de falências:{' '}
            <strong>
              {
                histories.CONTENT.protestos.bankrupts.bankruptsResponse.summary
                  .count
              }
            </strong>
          </p>
          <p>
            Valor total das falências:{' '}
            <strong>
              {
                histories.CONTENT.protestos.bankrupts.bankruptsResponse.summary
                  .balance
              }
            </strong>
          </p>
          <Divider style={{ marginTop: '3px', marginBottom: '3px' }} />
          Ações Judiciais
          <p>
            Data da ação judicial:{' '}
            <strong>
              {
                histories.CONTENT.protestos.judgementFilings
                  .judgementFilingsResponse.occurrenceDate
              }
            </strong>
          </p>
          <p>
            Natureza da dívida:{' '}
            <strong>
              {
                histories.CONTENT.protestos.judgementFilings
                  .judgementFilingsResponse.legalNature
              }
            </strong>
          </p>
          <p>
            Valor da dívida:{' '}
            <strong>
              {
                histories.CONTENT.protestos.judgementFilings
                  .judgementFilingsResponse.amount
              }
            </strong>
          </p>
          <p>
            ID da Vara Judicial:{' '}
            <strong>
              {
                histories.CONTENT.protestos.judgementFilings
                  .judgementFilingsResponse.civilCourt
              }
            </strong>
          </p>
          <p>
            Cidade:{' '}
            <strong>
              {
                histories.CONTENT.protestos.judgementFilings
                  .judgementFilingsResponse.city
              }
            </strong>
          </p>
          <p>
            Estado:{' '}
            <strong>
              {
                histories.CONTENT.protestos.judgementFilings
                  .judgementFilingsResponse.state
              }
            </strong>
          </p>
          <p>
            Data da negativação mais recente:{' '}
            <strong>
              {
                histories.CONTENT.protestos.judgementFilings.summary
                  .firstOcurrence
              }
            </strong>
          </p>
          <p>
            Data da negativação mais antiga:{' '}
            <strong>
              {
                histories.CONTENT.protestos.judgementFilings.summary
                  .lastOcurrence
              }
            </strong>
          </p>
          <p>
            Quantidade total de ações judiciais:{' '}
            <strong>
              {histories.CONTENT.protestos.judgementFilings.summary.count}
            </strong>
          </p>
          <p>
            Valor total das ações judiciais:{' '}
            <strong>
              {histories.CONTENT.protestos.judgementFilings.summary.balance}
            </strong>
          </p>
          <p>
            Quantidade de consultas nos cinco últimos meses:{' '}
            {histories.CONTENT.protestos.judgementFilings.inquiryCompanyResponse.results.map(
              (item) => (
                <div>
                  <p>
                    Data da consulta:<strong>{item.occurrences}</strong>
                  </p>
                  <p>
                    CNPJ da empresa consultante:
                    <strong>{item.companyName}</strong>
                  </p>
                  <p>
                    Razão Social da empresa consultante:
                    <strong>{item.companyDocumentId}</strong>
                  </p>
                  <p>
                    Quantidade de consultas no dia:
                    <strong>{item.daysQuantity}</strong>
                  </p>
                </div>
              ),
            )}
          </p>
          <p>
            Quantidade de consultas do mês atual:{' '}
            <strong>
              {
                histories.CONTENT.protestos.judgementFilings
                  .inquiryCompanyResponse.quantity.actual
              }
            </strong>
          </p>
        </div>
      );
    } else {
      return (
        <div>
          Falências
          <p>
            Quantidade total de falências:{' '}
            <strong>
              {histories.CONTENT.protestos.bankrupts.summary.count}
            </strong>
          </p>
          <p>
            Valor total das falências:{' '}
            <strong>
              {histories.CONTENT.protestos.bankrupts.summary.balance}
            </strong>
          </p>
          <Divider style={{ marginTop: '3px', marginBottom: '3px' }} />
          Ações Judiciais
          <p>
            Quantidade total de ações judiciais:{' '}
            <strong>
              {histories.CONTENT.protestos.judgementFilings.summary.count}
            </strong>
          </p>
          <p>
            Valor total das ações judiciais:{' '}
            <strong>
              {histories.CONTENT.protestos.judgementFilings.summary.balance}
            </strong>
          </p>
        </div>
      );
    }
  }

  function CND(histories) {
    if (histories.CONTENT.cnd.nome) {
      return (
        <div className="divCND">
          <p>
            Razão Social: <strong>{histories.CONTENT.cnd.nome}</strong>
          </p>
          <p>
            CNPJ: <strong>{histories.CONTENT.cnd.cnpj}</strong>
          </p>
          <p>
            Certidão Emitida em:{' '}
            <strong>
              {histories.CONTENT.cnd.normalizado_consulta_datahora}
            </strong>{' '}
          </p>
          <p>
            Válida até: <strong>{histories.CONTENT.cnd.validade_data}</strong>
          </p>
          <p>
            Código de controle da certidão:{' '}
            <strong>{histories.CONTENT.cnd.certidao_codigo}</strong>
          </p>
          <p>
            Baixe aqui a Certidão em PDF:{' '}
            <IconButton>
              <a
                href={histories.CONTENT.cnd.site_receipt}
                target="_blank"
                rel="noreferrer"
              >
                <CloudDownloadIcon sx={{ color: '#2b2c5f' }} />
              </a>
            </IconButton>
          </p>
        </div>
      );
    } else if (histories.CONTENT.cnd.nome) {
      return (
        <div className="divCND">
          <p>
            Razão Social: <strong>{histories.CONTENT.cnd.nome}</strong>
          </p>
          <p>
            CNPJ: <strong>{histories.CONTENT.cnd.cnpj}</strong>
          </p>
          <p>
            Certidão Emitida em:{' '}
            <strong>
              {histories.CONTENT.cnd.normalizado_consulta_datahora}
            </strong>{' '}
          </p>
          <p>
            Válida até: <strong>{histories.CONTENT.cnd.validade_data}</strong>
          </p>
          <p>
            Código de controle da certidão:{' '}
            <strong>{histories.CONTENT.cnd.certidao_codigo}</strong>
          </p>
          <p>
            Baixe aqui a Certidão em PDF:{' '}
            <IconButton>
              <a
                href={histories.CONTENT.cnd.site_receipt}
                target="_blank"
                rel="noreferrer"
              >
                <CloudDownloadIcon sx={{ color: '#2b2c5f' }} />
              </a>
            </IconButton>
          </p>
        </div>
      );
    } else {
      return (
        <p>
          Podem haver pendências financeiras ou processuais no CNPJ da empresa
          consultada. Não será possível gerar a certidão.
        </p>
      );
    }
  }

  function SPC(histories) {
    if (histories.CONTENT.spc.spcAnnotations.inclusionDate) {
      return (
        <div>
          <p>
            Data da inclusão:{' '}
            <strong>
              {histories.CONTENT.spc.spcAnnotations.inclusionDate}
            </strong>
          </p>
          <p>
            Data da ocorrência:{' '}
            <strong>
              {histories.CONTENT.spc.spcAnnotations.ocurrenceDate}
            </strong>
          </p>
          <p>
            Credor da anotação:{' '}
            <strong>{histories.CONTENT.spc.spcAnnotations.creditorName}</strong>
          </p>
          <p>
            Origem da anotação:{' '}
            <strong>{histories.CONTENT.spc.spcAnnotations.origin}</strong>
          </p>
          <p>
            Data da negativação mais recente:{' '}
            <strong>
              {histories.CONTENT.spc.spcAnnotations.summary.firstOcurrence}
            </strong>
          </p>
          <p>
            Data da negativação mais antiga:{' '}
            <strong>
              {histories.CONTENT.spc.spcAnnotations.summary.lastOcurrence}
            </strong>
          </p>
          <p>
            Total de anotações:{' '}
            <strong>
              {histories.CONTENT.spc.spcAnnotations.summary.count}
            </strong>
          </p>
          <p>
            Valor total das anotações: R$
            <strong>
              {histories.CONTENT.spc.spcAnnotations.summary.balance}
            </strong>
          </p>
          <p>
            Quantidade de consultas no mês atual:{' '}
            <strong>
              {
                histories.CONTENT.spc.spcMarketInquirySummary.inquiryQuantity
                  .actual
              }
            </strong>
          </p>
          <p>
            Quantidade de consultas nos últimos 6 meses:{' '}
            <strong>
              {
                histories.CONTENT.spc.spcMarketInquirySummary.inquiryQuantity
                  .creditInquiriesQuantity
              }
            </strong>
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            Quantidade de consultas no mês atual:{' '}
            <strong>
              {
                histories.CONTENT.spc.spcMarketInquirySummary.inquiryQuantity
                  .actual
              }
            </strong>
          </p>
          <p>
            Quantidade de consultas nos últimos 6 meses:{' '}
            <strong>
              {
                histories.CONTENT.spc.spcMarketInquirySummary.inquiryQuantity
                  .creditInquiriesQuantity[0].occurrences
              }
            </strong>
          </p>
        </div>
      );
    }
  }
  return (
    <>
      <Header />
      <div className="containerTotal">
        <div className="container">
          <h3 className="h3Empresa" style={{ color: '#2b2c5f' }}>
            Selecione uma empresa e filial para realizar uma consulta avançada
          </h3>
          <div className="item-1">
            <FormControl size="small">
              <StyledSelect
                id="empresa-select"
                displayEmpty
                value={company}
                onChange={handleChange}
              >
                {companies.map((company) => {
                  return (
                    <MenuItem
                      key={company.IDCOMPANY}
                      value={`${company.CNPJ} - ${company.RAZAOSOCIAL}`}
                    >
                      {`${company.CNPJ} - ${company.RAZAOSOCIAL}`}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
            {company ? (
              <FormControl size="small">
                <StyledSelect
                  id="empresa-select"
                  displayEmpty
                  value={filial}
                  onChange={handleChangeFilial}
                >
                  {filiais.map((filial) => {
                    return (
                      <MenuItem
                        key={filial.IDSUBSIDIARY}
                        value={`${filial.CNPJFILIAL} - ${filial.RAZAOSOCIAL}`}
                      >
                        {`${filial.CNPJFILIAL} - ${filial.RAZAOSOCIAL}`}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
                <DialogAvançada CNPJConsulta={CNPJConsulta} />
              </FormControl>
            ) : null}
          </div>
          <section className="sectionBG">
            <Divider />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <h3
                className="h3Empresa"
                style={{ color: '#2b2c5f', textAlign: 'center' }}
              >
                {' '}
                Histórico de Consultas
              </h3>

              {loading ? (
                <Box>
                  <CircularProgress style={{ color: '#2b2c5f' }} />
                </Box>
              ) : (
                history?.map((histories, index) => {
                  return (
                    <Accordion>
                      <AccordionSummary
                        key={index}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="accordion-history"
                        id="accordion-history"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <p style={{ color: '#252525' }}>
                            <strong>Data da Consulta:</strong>{' '}
                            {histories.CREATEDON} -{' '}
                            <strong>Realizado por:</strong> {histories.USER}
                          </p>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="divDadosEmpresa">
                          <p className="pEmpresa">Dados Empresa </p>

                          <GaugeHistory histories={histories} />

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">
                                  Número de Inscrição e Razão Social
                                </th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">
                                  {histories.CONTENT.cnpj} -{' '}
                                  {histories.CONTENT.nome}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">Última Atualização</th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">
                                  {histories.CONTENT.ultima_atualizacao}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">Data de Abertura</th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">
                                  {histories.CONTENT.abertura}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">
                                  Atividade Econômica Primária
                                </th>
                              </tr>
                              <tr className="tr">
                                <th className="th">Código</th>
                                <th className="th">Descrição</th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">
                                  {
                                    histories.CONTENT.atividade_principal[0]
                                      .code
                                  }
                                </td>
                                <td className="td">
                                  {
                                    histories.CONTENT.atividade_principal[0]
                                      .text
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">
                                  Atividade Econômica Secundárias
                                </th>
                              </tr>
                              <tr className="tr">
                                <th className="th">Código</th>
                                <th className="th">Descrição</th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              {histories.CONTENT.atividades_secundarias.map(
                                (secondaryActivity, index) => {
                                  return (
                                    <tr className="tr" key={index}>
                                      <td className="td">
                                        {secondaryActivity.code}
                                      </td>
                                      <td className="td">
                                        {secondaryActivity.text}
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">
                                  Código e Descrição da Natureza Jurídica
                                </th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">
                                  {histories.CONTENT.natureza_juridica}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">Logradouro</th>
                                <th className="th">Número</th>
                                <th className="th">Complemento</th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">
                                  {histories.CONTENT.logradouro}
                                </td>
                                <td className="td">
                                  {histories.CONTENT.numero}
                                </td>
                                <td className="td">
                                  {histories.CONTENT.complemento}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">CEP</th>
                                <th className="th">Bairro</th>
                                <th className="th">Município</th>
                                <th className="th">UF</th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">{histories.CONTENT.cep}</td>
                                <td className="td">
                                  {histories.CONTENT.bairro}
                                </td>
                                <td className="td">
                                  {histories.CONTENT.municipio}
                                </td>
                                <td className="td">{histories.CONTENT.uf}</td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">Endereço Eletrônico</th>
                                <th className="th">Telefone</th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">
                                  {histories.CONTENT.email}
                                </td>
                                <td className="td">
                                  {histories.CONTENT.telefone}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">Capital Social</th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">
                                  {numberToReal(
                                    +histories.CONTENT.capital_social,
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">Quadro Societário</th>
                              </tr>
                              <tr className="tr">
                                <td className="th">Nome</td>
                                <td className="th">Qualificação</td>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              {histories.CONTENT.qsa.map((qsa, i) => {
                                if (qsa.qual_rep_legal && qsa.nome_rep_legal) {
                                  return (
                                    <tr className="tr" key={i}>
                                      <td className="td">
                                        Nome: {qsa.nome} - Representante Legal:{' '}
                                        {qsa.nome_rep_legal}
                                      </td>
                                      <td className="td">{qsa.qual}</td>
                                    </tr>
                                  );
                                }
                                return (
                                  <tr className="tr">
                                    <td className="td">{qsa.nome}</td>
                                    <td className="td">{qsa.qual}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">Situação Cadastral</th>
                                <th className="th">
                                  Data da Situação Cadastral
                                </th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">
                                  {histories.CONTENT.situacao}
                                </td>
                                <td className="td">
                                  {histories.CONTENT.data_situacao}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">
                                  Certidão Negativa de Débitos aos Tributos
                                  Federais e à Dívida Ativa da União
                                </th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">
                                  <div className="divCND">{CND(histories)}</div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">SPC</th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">
                                  <div className="divSPC">{SPC(histories)}</div>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table className="table">
                            <thead className="thead">
                              <tr className="tr">
                                <th className="th">Protestos</th>
                              </tr>
                            </thead>
                            <tbody className="tbody">
                              <tr className="tr">
                                <td className="td">{Protestos(histories)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ConsultAvancada;
