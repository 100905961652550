
import { createRoot } from 'react-dom/client';
import App from './App';
import { ProSidebarProvider } from 'react-pro-sidebar';
import 'react-phone-number-input/style.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <ProSidebarProvider>
      <App />
    </ProSidebarProvider>

);
