import React, { useState, useEffect } from 'react';
import Head from './Components/Head';
import Header from './Components/Header';
import NavBar from './Components/navBar';
import { Paper, Stack } from '@mui/material';
import ModalNotificacoes from './Components/ModalNotificacoes';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import './Components/Orgaos.css';
import { TableContainer } from '@mui/material';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import AcoesNotificacoes from './Components/AcoesNotificacoes';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { API_BASE, API_URL } from './Components/API/apiManager';

function Notificações() {
  const [dataTable, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + '/notifications', { headers: { Base: API_BASE } })
      .then((response) => {
        setLoading(false);
        setTableData(response.data);
      });
  }, []);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataTable.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Head title="Sonar | Notificações" />
      <Header />
      <ModalNotificacoes />
      <Stack className="stackContainerGrid">
        <TableContainer component={Paper} style={{ borderRadius: '0.75rem' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">E-mail</TableCell>
                <TableCell align="center">Recebe E-MAIL?</TableCell>
                <TableCell align="center">Celular</TableCell>
                <TableCell align="center">Recebe SMS?</TableCell>
                <TableCell align="center">Baixo</TableCell>
                <TableCell align="center">Médio</TableCell>
                <TableCell align="center">Alto</TableCell>
                <TableCell align="left">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ p: 0, m: 0 }}>
              {loading ? (
                <Box className="ProgressBar">
                  <CircularProgress style={{ color: '#2b2c5f' }} />
                </Box>
              ) : (
                (rowsPerPage > 0
                  ? dataTable.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : dataTable
                ).map((item, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell align="center">
                        {item.IDNOTIFICATION}
                      </TableCell>
                      <TableCell align="center">{item.EMAIL}</TableCell>
                      <TableCell
                        align="center"
                        size="small"
                        style={{ width: 60 }}
                      >
                        {item.SENDMAIL === true ? (
                          <CheckIcon style={{ color: 'green' }} />
                        ) : (
                          <ClearIcon style={{ color: 'red' }} />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {item.PHONENUMBER.replace(
                          /^(\d\d)(\d{5})(\d{4}).*/,
                          '($1) $2-$3',
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {item.SENDSMS ? (
                          <CheckIcon style={{ color: 'green' }} />
                        ) : (
                          <ClearIcon style={{ color: 'red' }} />
                        )}
                      </TableCell>
                      <TableCell align="center" size="small">
                        {item.LOW ? (
                          <CheckIcon style={{ color: 'green' }} />
                        ) : (
                          <ClearIcon style={{ color: 'red' }} />
                        )}
                      </TableCell>
                      <TableCell align="center" size="small">
                        {item.MEDIUM ? (
                          <CheckIcon style={{ color: 'green' }} />
                        ) : (
                          <ClearIcon style={{ color: 'red' }} />
                        )}
                      </TableCell>
                      <TableCell align="center" size="small">
                        {item.HIGH ? (
                          <CheckIcon style={{ color: 'green' }} />
                        ) : (
                          <ClearIcon style={{ color: 'red' }} />
                        )}
                      </TableCell>
                      <TableCell>
                        <AcoesNotificacoes notificacao={item} size="small" />
                      </TableCell>
                    </TableRow>
                  );
                })
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'Todas', value: -1 }]}
            colSpan={3}
            count={dataTable.length}
            rowsPerPage={rowsPerPage}
            component="div"
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Stack>
    </>
  );
}

export default Notificações;
