import { React, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AccountContext } from './Account';
import LoginPage from './Pages/LoginPage';
import '../Components/Pages/LoginPage.css';
import RPA from './imgs/RPABranco.png';
import BackgroundLogo from './Background';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const { authenticate } = useContext(AccountContext);

  useEffect(() => {
    const storedEmail = localStorage.getItem('rememberedEmail');
    if (storedEmail) {
      setEmail(storedEmail);
      setRememberMe(true);
    }
    // console.log(storedEmail);
  }, []);

  function handleRememberMeChange(e) {
    setRememberMe(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem('rememberedEmail', email);
    } else {
      localStorage.removeItem('rememberedEmail');
    }
  }

  const onSubmit = (event) => {
    event.preventDefault();

    authenticate(email, password)
      .then((data) => {
        sessionStorage.setItem('token', data.accessToken.jwtToken);
        sessionStorage.setItem('userSession', data.idToken.payload.email);
        window.location.href = '/home';
      })
      .catch((err) => {
        let message = document.querySelector('span');
        message.innerText = 'E-mail ou senha incorretos.';
      });
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, 'nooopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const onClickUrl = (url) => {
    return () => openInNewTab(url);
  };

  return (
    <div>
      <LoginPage />
      <div>
        <p className="bemVindo">Bem-vindo!</p>
      </div>
      <form onSubmit={onSubmit} className="formulario">
        <input
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="e-mail"
          className="emailLogin"
        />
        <input
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          placeholder="senha"
          className="senhaLogin"
        />

        <div
          className="lembrarSenha"
          style={{
            display: 'flex',
          }}
        >
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={handleRememberMeChange}
            className="senhaLogin"
            label="Lembrar-me"
          />
          <label
            htmlFor="senhaLogin"
            // style={{ position: 'relative', top: '-2px' }}
          >
            Lembrar-me
          </label>
        </div>
        <span className="message"></span>
        <div className="loginBTN">
          <button type="submit" className="botaoLogar">
            Login
          </button>
        </div>
        <button id="botao">
          <Link to="/resetarSenha" className="LinkEsqueciSenha">
            Esqueci minha senha
          </Link>
        </button>
      </form>
      <section className="section">
        <p
          className="contato"
          onClick={() => openInNewTab('https://wa.me/552139235970')}
          style={{ cursor: 'pointer' }}
        >
          Fale Conosco
        </p>
        <img
          src={RPA}
          alt="logo RPA Pollvo"
          width="90"
          className="RPA"
          onClick={() =>
            openInNewTab('https://www.pollvo.com/index.php/solucoes/rpa-2/')
          }
        />
      </section>
      <section className="background">
        <BackgroundLogo />
      </section>
    </div>
  );
};

export default Login;
