import React from 'react';
import Highcharts from 'highcharts/highcharts.js';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import HighchartsReact from 'highcharts-react-official';

const Gauge = ({ score }) => {
  const [data, setData] = React.useState(score.score);
  highchartsMore(Highcharts);
  solidGauge(Highcharts);

  function Score(value) {
    if (value <= 300) {
      return (
        <div style={{ color: '#252525' }}>
          <p>
            Score{' '}
            <span style={{ color: '#DF5353', fontWeight: 'bold' }}>baixo</span>!
          </p>
        </div>
      );
    } else if (value <= 500) {
      return (
        <div style={{ color: '#252525' }}>
          <p>
            Score{' '}
            <span style={{ color: '#FFAA33', fontWeight: 'bold' }}>
              regular
            </span>
            !
          </p>
        </div>
      );
    } else if (value <= 700) {
      return (
        <div style={{ color: '#252525' }}>
          <p>
            Score{' '}
            <span style={{ color: '#B4C424', fontWeight: 'bold' }}>bom</span>!
          </p>
        </div>
      );
    } else {
      return (
        <div style={{ color: '#252525' }}>
          <p>
            Score{' '}
            <span style={{ color: '#55BF3B', fontWeight: 'bold' }}>
              excelente
            </span>
            !
          </p>
        </div>
      );
    }
  }

  const options = {
    chart: {
      type: 'solidgauge',
      backgroundColor: 'transparent',
      width: 250, // Defina a largura desejada em pixels
      height: 200, // Defina a altura desejada em pixels
    },
    pane: {
      center: ['50%', '85%'],
      size: '140%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
      },
    },
    title: {
      text: 'Medidor Score',
      style: {
        fontSize: '18px',
        fontWeight: 600,
        fontFamily: 'Calibri',
        color: '#252525',
      },
    },
    yAxis: {
      min: 0,
      max: 1000,
      title: {
        text: 'Valor Score',
      },
      stops: [
        [0.3, '#DF5353'], //baixo
        [0.301, '#ED7014'], // regular
        [0.5, '#ED7014'], //regular
        [0.501, '#DDDF0D'], //bom
        [0.7, '#55BF3B'], // otimo
        [0.8, '#55BF3B'], // otimo
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70,
      },
      labels: {
        y: 16,
      },
    },
    series: [
      {
        name: 'Score',
        data: [data],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y}</span><br/>' +
            '<span style="font-size:12px;opacity:0.4">Score</span>' +
            '</div>',
        },
      },
    ],
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
      {Score(Number(data))}
    </div>
  );
};

export default Gauge;
