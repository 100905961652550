import React from 'react';
import { Card } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { CircularProgress, Box } from '@mui/material';

const CardConsulta = ({ children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return <Card sx={{ width: isMobile ? 250 : 270 }}>{children}</Card>;
};

export default CardConsulta;
