import React, { useState } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import warning from './imgs/warning.svg';
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 250,
  color: '#252525',
  bgcolor: 'background.paper',
  border: '1px solid #2b2c5f',
  boxShadow: 24,
  borderRadius: '0.75rem',
  p: 4,
};

function LeituraMensagemECAC({ mensagem }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="contained"
        startIcon={<VisibilityIcon />}
        fullWidth
        sx={{
          marginTop: '12rem',
          color: '#fff',
          backgroundColor: '#2b2c5f',
          ':hover': {
            backgroundColor: '#3b4e82 ',
            color: '#fff',
            borderColor: '#2b2c5f',
          },
        }}
      >
        Visualizar Mensagem
      </Button>
      <Modal open={open}>
        <Box sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img src={warning} />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h1"
            textAlign="center"
          >
            Cuidado!
          </Typography>
          <Typography id="modal-modal-description" textAlign="center">
            Tem certeza que deseja visualizar esta mensagem?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 1, mb: 0 }}
            textAlign="center"
          >
            Ao clicar em <b style={{ color: 'red' }}>SIM</b>, a mensagem será
            automaticamente marcada com lida na Receita Federal.
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <Button
              variant="contained"
              endIcon={<VisibilityIcon />}
              sx={{ mr: 2 }}
              style={{ backgroundColor: 'green' }}
            >
              Sim, visualizar agora!
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              style={{ backgroundColor: 'red' }}
            >
              Cancelar!
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default LeituraMensagemECAC;
