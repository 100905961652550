import React, { useState, useReducer } from 'react';
import InputMask from 'react-input-mask';
import { Divider, Box, IconButton, Button } from '@mui/material';
import './DadosFiliais.css';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Stack,
} from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useEffect } from 'react';
import { API_BASE, API_URL } from './API/apiManager';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './DadosEmpresas.css';
import { ElectricScooter } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const filialInicial = {
  IDSUBSIDIARY: '',
  RAZAOSOCIAL: '',
  CNPJFILIAL: '',
  INSCRICAOMUNICIPAL: '',
  ENDERECO: '',
  NUMENDERECO: '',
  COMPLENDERECO: '',
  BAIRRO: '',
  CIDADE: '',
  UF: '',
  CEP: '',
};

export default function DadosFiliais({ cnpj }) {
  const [filiais, setFiliais] = useState([{ ...filialInicial }]);
  const [message, setMessage] = useState('');

  const [openError, setOpenError] = React.useState(false);
  const [openEmpty, setOpenEmpty] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);

  const handleGetFiliais = async () => {
    try {
      const headers = {
        Base: API_BASE,
        CNPJCompany: cnpj,
      };

      const res = await axios.get(API_URL + '/subsidiaries/bycnpj', {
        headers: headers,
      });
      const result = res.data;
      setFiliais(result);
    } catch (err) {
      //  console.log(err)
    }
  };
  useEffect(() => {
    if (cnpj) {
      handleGetFiliais();
    }
  }, []);
  // console.log(cnpj);
  const handleClick = () => {
    setFiliais([
      ...filiais,
      { ...filialInicial, IDSUBSIDIARY: filiais.at(-1).IDSUBSIDIARY + 1 },
    ]);
  };

  const handleDelete = (index) => {
    const newArray = [...filiais];
    newArray.splice(index, 1);
    setFiliais(newArray);
  };

  const handleOnChange = (index, valueToChange, e) => {
    const novaArray = [...filiais];
    novaArray[index][valueToChange] = e.target.value;
    setFiliais(novaArray);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
    setOpenEmpty(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenWarning(false);
  };

  function RemoverCaracteres(value) {
    return value.normalize('NFD').replace(/[^a-zA-Z0-9]/g, '');
  }

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  function RemoverAcentos(value) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  const handlePostFilial = async (e) => {
    e.preventDefault();
    const header = filiais.map(async (item) => {
      const headers = {
        Base: API_BASE,
        cnpjcompany: cnpj,
        cnpjsubsidiary: item.CNPJFILIAL,
        razaosocial: RemoverAcentos(item.RAZAOSOCIAL),
        inscricaomunicipal: RemoverCaracteres(item.INSCRICAOMUNICIPAL),
        cep: item.CEP,
        endereco: RemoverCaracteres(item.ENDERECO),
        numendereco: RemoverCaracteres(item.NUMENDERECO),
        complendereco: RemoverCaracteres(item.COMPLENDERECO),
        user: sessionStorage.getItem('userSession'),
      };
      try {
        const res = await axios.post(
          API_URL + '/subsidiaries/bycnpj',
          {},
          {
            headers: headers,
          },
        );

        if (res.status === 200) {
          setOpenSuccess(true);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 2000);
        } else if (res.status !== 200) {
          setOpenError(true);
        }
      } catch (err) {
        setOpenError(true);
      }
    });
  };

  return (
    <div>
      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: '100%' }}
        >
          Sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Erro no envio!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openEmpty}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          Certificado, Senha e ao menos uma filial não pode estar em branco!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openWarning}
        autoHideDuration={4000}
        onClose={handleCloseWarning}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseWarning}
          severity="warning"
          sx={{ width: '100%' }}
        >
          Dados faltante!
        </Alert>
      </Snackbar>

      <Divider>Cadastrar Filial</Divider>
      <div className="containerTable">
        <Box className="box">
          <IconButton onClick={handleClick} id="addInput">
            <AddIcon fontSize="inherit" />
          </IconButton>
          <form onSubmit={handlePostFilial} className="formFiliais">
            <Stack className="stackFiliais">
              <TableContainer className="FilialTable">
                <Table size="small" padding="none">
                  <TableHead>
                    <TableRow id="labelTable">
                      <TableCell align="center" sx={{ color: '#fff' }}>
                        CNPJ Filial
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#fff' }}>
                        Razão Social
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#fff' }}>
                        Insc. Mun.
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#fff' }}>
                        CEP
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#fff' }}>
                        Endereço
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#fff' }}>
                        Número
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#fff' }}>
                        Complemento
                      </TableCell>
                      <TableCell align="center" sx={{ color: '#fff' }}>
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filiais.length &&
                      filiais.map((item, index) => {
                        return (
                          <TableRow
                            key={item.IDSUBSIDIARY}
                            className="trFiliais"
                          >
                            <TableCell className="CNPJFilial">
                              <InputMask
                                className={index}
                                // mask="99.999.999/9999-99"
                                id="inputFiliais"
                                type="text"
                                value={item.CNPJFILIAL || cnpj}
                                onChange={(e) =>
                                  handleOnChange(index, 'CNPJFILIAL', e)
                                }
                              />
                            </TableCell>
                            <TableCell className="RazaoSocialFilial">
                              <input
                                className={index}
                                type="text"
                                placeholder="Razão Social"
                                id="inputFiliais"
                                value={item.RAZAOSOCIAL || ' '}
                                onChange={(e) =>
                                  handleOnChange(index, 'RAZAOSOCIAL', e)
                                }
                              />
                            </TableCell>
                            <TableCell className="InscMunFilial">
                              <input
                                className={index}
                                type="text"
                                placeholder="Insc. Municipal"
                                id="inputFiliais"
                                value={item.INSCRICAOMUNICIPAL || ' '}
                                onChange={(e) =>
                                  handleOnChange(index, 'INSCRICAOMUNICIPAL', e)
                                }
                              />
                            </TableCell>
                            <TableCell className="CEPFilial">
                              <InputMask
                                className={index}
                                mask="99999-999"
                                type="text"
                                name="cep"
                                placeholder="CEP"
                                id="inputFiliais"
                                value={item.CEP || ' '}
                                onChange={(e) =>
                                  handleOnChange(index, 'CEP', e)
                                }
                              />
                            </TableCell>
                            <TableCell className="numeroFilial">
                              <input
                                className={index}
                                type="text"
                                id="inputFiliais"
                                value={item.ENDERECO || ' '}
                                onChange={(e) =>
                                  handleOnChange(index, 'ENDERECO', e)
                                }
                              />
                            </TableCell>
                            <TableCell className="numeroFilial">
                              <input
                                className={index}
                                type="text"
                                name="numero"
                                placeholder="Número"
                                id="inputFiliais"
                                value={item.NUMENDERECO || ' '}
                                onChange={(e) =>
                                  handleOnChange(index, 'NUMENDERECO', e)
                                }
                              />
                            </TableCell>
                            <TableCell className="complementoFilial">
                              <input
                                className={index}
                                type="text"
                                name="complemento"
                                placeholder="Compl."
                                id="inputFiliais"
                                value={item.COMPLENDERECO || ' '}
                                onChange={(e) =>
                                  handleOnChange(index, 'COMPLENDERECO', e)
                                }
                              />
                            </TableCell>
                            <TableCell className="deleteFilial">
                              <IconButton
                                style={{ width: '70px' }}
                                className={index}
                                onClick={() => handleDelete(index)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                id="Button"
                type="submit"
                style={{ position: 'relative', top: 25 }}
              >
                Salvar <SaveAltIcon />
              </Button>
            </div>
          </form>
          {message ? (
            <p
              style={{ color: 'green', textAlign: 'center', marginTop: '1rem' }}
            >
              {message}
            </p>
          ) : (
            <p style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}>
              {message}
            </p>
          )}
        </Box>
      </div>
    </div>
  );
}
